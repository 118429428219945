import React, { useContext } from 'react';

import { Link } from 'react-router-dom';
import { Card, Button } from 'react-bootstrap';
import Note from './Note';
import axios from 'axios';
import { Store } from '../Store';

function Produit(props) {
  const { produit } = props;

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    panier: { elementsPanier },
  } = state;

  const gestionAjoutPanier = async (element) => {
    const elementExiste = elementsPanier.find((x) => x._id === produit._id);
    const quantite = elementExiste ? elementExiste.quantite + 1 : 1;
    const { data } = await axios.get(`api/produits/${element._id}`);
    if (data.nombreEnStock < quantite) {
      window.alert('Désolé. Le produit est en rupture de stock');
      return;
    }
    ctxDispatch({
      type: 'PANIER_AJOUT_ELEMENT',
      payload: { ...element, quantite },
    });
  };

  return (
    <Card>
      <Link to={`/produit/${produit.slug}`}>
        <img src={produit.image} alt={produit.nom} className="img-fluid " />
      </Link>
      <Card.Body>
        <Link to={`/produit/${produit.slug}`}>
          <Card.Title>{produit.nom}</Card.Title>
        </Link>
        <Note note={produit.note} nombreDavis={produit.nombreDavis}></Note>
        <Card.Text>{produit.prix} XAF</Card.Text>
        {produit.nombreEnStock === 0 ? (
          <Button variant="light" disabled>
            En rupture de stock
          </Button>
        ) : (
          <Button onClick={() => gestionAjoutPanier(produit)}>
            Ajouter au panier
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default Produit;
