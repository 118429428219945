import React, { useContext, useEffect, useReducer } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import { Table, Button, Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../utils';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_REQUETE_UTILISATEURS':
      return { ...state, loading: true };
    case 'IMPORT_SUCCES_UTILISATEURS':
      return { ...state, utilisateurs: action.payload, loading: false };
    case 'IMPORT_ECHEC_UTILISATEURS':
      return { ...state, loading: false, error: action.payload };
    case 'SUPPRIMER_UTILISATEUR_REQUETE':
      return { ...state, loadingSupprimer: true };
    case 'SUPPRIMER_UTILISATEUR_SUCCES':
      return {
        ...state,
        loadingSupprimer: false,
        succesSupprimer: true,
        messageSupprimer: action.payload,
      };
    case 'SUPPRIMER_UTILISATEUR_ECHEC':
      return {
        ...state,
        loadingSupprimer: false,
        errorSupprimer: action.payload,
      };
    case 'SUPPRIMER_UTILISATEUR_RESET':
      return {
        ...state,
        loadingSupprimer: false,
        succesSupprimer: false,
      };
    default:
      return state;
  }
};

export default function EcranListeUtilisateurs() {
  const [
    {
      loading,
      loadingSupprimer,
      error,
      errorSupprimer,
      succesSupprimer,
      messageSupprimer,
      utilisateurs,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    loadingSupprimer: false,
    errorSupprimer: false,
    succesSupprimer: false,
    messageSupprimer: '',
    utilisateurs: [],
    error: '',
  });

  const navigate = useNavigate();
  const numeroPage = useParams();

  const { state } = useContext(Store);
  const { infoUtilisateur } = state;

  useEffect(() => {
    // if (!infoUtilisateur.estAdmin) {
    //   navigate('/signin');
    // }
    const importUtilisateurs = async () => {
      dispatch({ type: 'IMPORT_REQUETE_UTILISATEURS' });
      try {
        const { data } = await axios.get('/api/utilisateurs', {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'IMPORT_SUCCES_UTILISATEURS', payload: data });
      } catch (err) {
        dispatch({ type: 'IMPORT_ECHEC_UTILISATEURS', payload: getError(err) });
        toast.error(error);
      }
    };
    importUtilisateurs();
    if (!utilisateurs) {
      importUtilisateurs();
    } else if (succesSupprimer) {
      toast.success("L'utilisateur a été supprimé avec succès.");
      dispatch({ type: 'SUPPRIMER_UTILISATEUR_RESET' });
      importUtilisateurs();
    }
  }, [dispatch, succesSupprimer, infoUtilisateur, navigate]);

  const gestionSuppressionUtilisateur = async (id) => {
    if (
      window.confirm('Etes-vous sûre de vouloir supprimer cet utilisateur?')
    ) {
      try {
        dispatch({ type: 'SUPPRIMER_UTILISATEUR_REQUETE' });
        const { data } = await axios.delete(`/api/utilisateurs/${id}`, {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'SUPPRIMER_UTILISATEUR_SUCCES', payload: data });
        toast.success(messageSupprimer);
      } catch (err) {
        dispatch({
          type: 'SUPPRIMER_UTILISATEUR_ECHEC',
          payload: getError(err),
        });
        toast.error(errorSupprimer);
      }
    }
  };

  return (
    <>
      <Container>
        <Helmet>
          <title>Liste des utilisateurs</title>
        </Helmet>
        <Row className="my-5">
          <Col>
            {' '}
            <h2 className="couleur-titre-standard">Liste des utilisateurs</h2>
          </Col>
        </Row>
        {errorSupprimer && (
          <WidgetMessage variant="danger">{errorSupprimer}</WidgetMessage>
        )}
        {loading ? (
          <WidgetChargement />
        ) : error ? (
          <WidgetMessage variant="danger">{error}</WidgetMessage>
        ) : (
          <>
            <Table striped bordered hover responsive className="table-sm mb-5">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>NOM</th>
                  <th>EMAIL</th>
                  <th>EST ADMIN</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {utilisateurs.map((utilisateur) => (
                  <tr key={utilisateur._id}>
                    <td>{utilisateur._id}</td>
                    <td>{utilisateur.nom}</td>
                    <td>{utilisateur.email}</td>
                    <td>{utilisateur.estAdmin ? 'OUI' : 'NON'}</td>
                    <td>
                      <LinkContainer
                        to={`/admin/dashboard/utilisateurs/${utilisateur._id}/edit`}
                      >
                        <Button variant="light" className="btn-sm">
                          <i className="fas fa-edit"></i>
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() =>
                          gestionSuppressionUtilisateur(utilisateur._id)
                        }
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                      {loadingSupprimer && <WidgetChargement />}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </>
        )}
      </Container>
    </>
  );
}
