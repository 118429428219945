import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import {
  Form,
  FormGroup,
  FormLabel,
  FormControl,
  Button,
} from 'react-bootstrap';
import { Store } from '../Store';
import EtapesCaisse from '../composants/EtapesCaisse';

export default function EcranAdresseExpedition() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    infoUtilisateur,
    panier: { adresseExpedition },
  } = state;
  const [fullName, setFullName] = useState(adresseExpedition.fullName || '');
  const [adresse, setAdresse] = useState(adresseExpedition.adresse || '');
  const [ville, setVille] = useState(adresseExpedition.ville || '');
  const [codePostal, setCodePostal] = useState(
    adresseExpedition.codePostal || ''
  );
  const [pays, setPays] = useState(adresseExpedition.pays || '');
  useEffect(() => {
    if (!infoUtilisateur) {
      navigate('/signin?redirect=/expedition');
    }
  }, [infoUtilisateur, navigate]);

  const gestionSoumission = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: 'SAUVEGARDER_ADRESSE_EXPEDITION',
      payload: {
        fullName,
        adresse,
        ville,
        codePostal,
        pays,
      },
    });
    localStorage.setItem(
      'adresseExpedition',
      JSON.stringify({ fullName, adresse, ville, codePostal, pays })
    );
    navigate('/paiement');
  };
  return (
    <div>
      <Helmet>
        <title>Adresse Expedition</title>
      </Helmet>
      <EtapesCaisse etape1 etape2 />
      <div className="container small-container">
        <h1 className="my-3">Adresse d'Expedition</h1>
        <Form onSubmit={gestionSoumission}>
          <FormGroup>
            <FormLabel>Nom Complet</FormLabel>
            <FormControl
              value={fullName}
              onChange={(e) => setFullName(e.target.value)}
              required
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <FormLabel>Adresse</FormLabel>
            <FormControl
              value={adresse}
              onChange={(e) => setAdresse(e.target.value)}
              required
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <FormLabel>Ville</FormLabel>
            <FormControl
              value={ville}
              onChange={(e) => setVille(e.target.value)}
              required
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <FormLabel>Code Postal</FormLabel>
            <FormControl
              value={codePostal}
              onChange={(e) => setCodePostal(e.target.value)}
              required
            ></FormControl>
          </FormGroup>
          <FormGroup>
            <FormLabel>Pays</FormLabel>
            <FormControl
              value={pays}
              onChange={(e) => setPays(e.target.value)}
              required
            ></FormControl>
          </FormGroup>

          <div className="my-3">
            <Button variant="primary" type="submit">
              Continuer
            </Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
