import React, { useContext, useEffect, useReducer } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Table,
  Button,
  Container,
  Row,
  Col,
  Pagination,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getError } from '../utils';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_REQUETE_COMMANDES':
      return { ...state, loading: true };
    case 'IMPORT_SUCCES_COMMANDES':
      return {
        ...state,
        commandes: action.payload.commandes,
        pageCmde: action.payload.pageCmde,
        pagesCmde: action.payload.pagesCmde,
        loading: false,
      };
    case 'IMPORT_ECHEC_COMMANDES':
      return { ...state, loading: false, error: action.payload };
    case 'SUPPRIMER_COMMANDE_REQUETE':
      return { ...state, loadingSupprimer: true };
    case 'SUPPRIMER_COMMANDE_SUCCES':
      return {
        ...state,
        loadingSupprimer: false,
        succes: true,
      };
    case 'SUPPRIMER_COMMANDE_ECHEC':
      return {
        ...state,
        loadingSupprimer: false,
        errorSupprimer: action.payload,
      };
    case 'SUPPRIMER_COMMANDE_RESET':
      return {
        ...state,
        loadingSupprimer: false,
        succes: false,
      };
    default:
      return state;
  }
};

export default function EcranListeCommande() {
  const { numeroPage } = useParams();
  const navigate = useNavigate();

  const [
    {
      loading,
      loadingSupprimer,
      error,
      errorSupprimer,
      succes,
      commandes,
      pageCmde,
      pagesCmde,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    loadingSupprimer: false,
    errorSupprimer: false,
    succes: false,
    commandes: [],
    pageCmde: '',
    pagesCmde: '',
    error: '',
  });

  const { state } = useContext(Store);
  const { infoUtilisateur } = state;

  useEffect(() => {
    // if (!infoUtilisateur.estAdmin) {
    //   navigate('/signin');
    // }
    const importCommandes = async () => {
      dispatch({ type: 'IMPORT_REQUETE_COMMANDES' });
      try {
        const { data } = await axios.get(
          `/api/commandes?numeroPage=${numeroPage}`,
          {
            headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
          }
        );
        dispatch({ type: 'IMPORT_SUCCES_COMMANDES', payload: data });
      } catch (err) {
        dispatch({ type: 'IMPORT_ECHEC_COMMANDES', payload: getError(err) });
        toast.error(getError(err));
      }
    };
    if (succes) {
      toast.success('La commande a été supprimée avec succès.');
      importCommandes();
      dispatch({ type: 'SUPPRIMER_COMMANDE_RESET' });
    }
    importCommandes();
  }, [dispatch, succes, infoUtilisateur, navigate, numeroPage]);

  const gestionSuppressionCommande = async (id) => {
    if (
      window.confirm('Etes-vous sûre de vouloir supprimer cette commande ?')
    ) {
      try {
        dispatch({ type: 'SUPPRIMER_COMMANDE_REQUETE' });
        const { data } = await axios.delete(`/api/commandes/${id}`, {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'SUPPRIMER_COMMANDE_SUCCES', payload: data });
      } catch (err) {
        dispatch({ type: 'SUPPRIMER_COMMANDE_ECHEC', payload: getError(err) });
        toast.error(getError(err));
      }
    }
  };

  return (
    <>
      <Container>
        <Helmet>
          <title>Liste des Commandes</title>
        </Helmet>

        <Row className="my-5">
          <Col md={9}>
            <h2 className="couleur-titre-standard">Liste des Commandes</h2>
          </Col>
        </Row>
        {loadingSupprimer && <WidgetChargement />}
        {errorSupprimer && (
          <WidgetMessage variant="danger">{errorSupprimer}</WidgetMessage>
        )}
        {loading ? (
          <WidgetChargement />
        ) : error ? (
          <WidgetMessage variant="danger">{error}</WidgetMessage>
        ) : (
          <>
            <Table striped bordered hover responsive className="table-sm mb-5">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>PRIX</th>
                  <th>EST PAYEE</th>
                  <th>EST LIVREE</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {commandes.map((commande) => (
                  <tr key={commande._id}>
                    <td>{commande._id}</td>
                    <td>{commande.prixTotal}</td>
                    {commande.estPaye ? (
                      <td>{commande.payeLe}</td>
                    ) : (
                      <td>Non</td>
                    )}
                    {commande.estLivre ? (
                      <td>{commande.livreLe}</td>
                    ) : (
                      <td>Non</td>
                    )}
                    <td>
                      <LinkContainer to={`/commande/${commande._id}`}>
                        <Button type="button" variant="light">
                          Détails
                        </Button>
                      </LinkContainer>
                    </td>
                    <td>
                      <Button
                        variant="danger"
                        className="btn-sm"
                        onClick={() => gestionSuppressionCommande(commande._id)}
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {pagesCmde && pagesCmde > 1 ? (
              <Pagination className="my-5 text-center">
                {[...Array(pagesCmde).keys()].map((p) => (
                  <LinkContainer
                    key={p + 1}
                    to={`/admin/dashboard/commande/${p + 1}`}
                  >
                    <Pagination.Item active={p + 1 === pageCmde}>
                      {p + 1}
                    </Pagination.Item>
                  </LinkContainer>
                ))}
              </Pagination>
            ) : (
              ''
            )}
          </>
        )}
      </Container>
    </>
  );
}
