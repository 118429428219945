import React, { useState } from 'react';
import { Button, Form, InputGroup, FormControl } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';

export default function BarreRecherche() {
  const navigate = useNavigate();
  const [requete, setRequete] = useState('');
  const [query, setQuery] = useState('');
  const gestionSoumission = (e) => {
    e.preventDefault();
    // navigate(requete ? `/rechercher/?query=${requete}` : '/recherche');
    navigate(query ? `/rechercher/?query=${query}` : '/rechercher');
  };
  return (
    <Form
      className="d-flex me-auto barre-recherche"
      onSubmit={gestionSoumission}
    >
      <InputGroup>
        <FormControl
          type="text"
          name="q"
          id="q"
          // onChange={(e) => setRequete(e.target.value)}
          onChange={(e) => setQuery(e.target.value)}
          placeholder="Rechercher un article..."
          aria-label="Rechercher une article"
          aria-describedby="bouton-recherche"
        ></FormControl>
        <Button variant="outline-primary" type="submit" id="bouton-recherche">
          <i className="fas fa-search"></i>
        </Button>
      </InputGroup>
    </Form>
  );
}
