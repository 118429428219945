import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import {
  Button,
  Card,
  Col,
  ListGroup,
  ListGroupItem,
  Row,
} from 'react-bootstrap';
import { PayPalButton } from 'react-paypal-button-v2';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate, useParams } from 'react-router-dom';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import { Store } from '../Store';
import { getError } from '../utils';
import FlutterWave from '../composants/FlutterWave';

function reducer(state, action) {
  switch (action.type) {
    case 'IMPORT_REQUETE':
      return {
        ...state,
        loading: true,
        error: '',
      };
    case 'IMPORT_SUCCES':
      return {
        ...state,
        loading: false,
        commande: action.payload,
        error: '',
      };
    case 'IMPORT_ECHEC':
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case 'PAY_REQUEST':
      return { ...state, loadingPay: true };
    case 'PAY_SUCCESS':
      return { ...state, loadingPay: false, successPay: true };
    case 'PAY_FAIL':
      return { ...state, loadingPay: false, errorPay: action.payload };
    case 'PAY_RESET':
      return { ...state, loadingPay: false, successPay: false };
    case 'LIVRE_REQUEST':
      return { ...state, loadingLivre: true };
    case 'LIVRE_SUCCESS':
      return { ...state, loadingLivre: false, successLivre: true };
    case 'LIVRE_FAIL':
      return { ...state, loadingLivre: false, errorLivre: action.payload };
    default:
      return state;
  }
}

export default function EcranCommande() {
  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  const params = useParams();
  const { id: commandeId } = params;
  const navigate = useNavigate();
  const [
    {
      loading,
      error,
      commande,
      successPay,
      loadingPay,
      loadingLivre,
      successLivre,
      errorLivre,
      errorPay,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    commande: {},
    error: '',
    loadingPay: false,
    loadingLivre: false,
    errorPay: '',
    errorLivre: '',
    successPay: false,
    successLivre: false,
  });
  const [sdkReady, setSdkReady] = useState(false);

  const paiemantCommande = async (id, resultat) => {
    try {
      dispatch({ type: 'PAY_REQUEST' });
      const { data } = await axios.put(`/api/commandes/${id}/pay`, resultat, {
        headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
      });
      dispatch({ type: 'PAY_SUCCESS', payload: data });
      toast.success(
        `${resultat.payer.name.given_name} Vous avez reglé le paiement de votre commande`
      );
    } catch (err) {
      toast.error(getError(err));
    }
  };

  const marquerCommandeLivre = async (id) => {
    try {
      dispatch({ type: 'LIVRE_REQUEST' });
      const { data } = await axios.put(`/api/commandes/${id}/livre`, {
        headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
      });
      dispatch({ type: 'LIVRE_SUCCESS', payload: data });
      toast.success(`Cette commande est désormais livrée.`);
    } catch (err) {
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    const addPayPalScript = async () => {
      const { data: client_ID } = await axios.get(`/api/keys/paypal`, {
        headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
      });
      // const script = document.createElement('script');
      // script.type = 'text/javascript';
      // script.src = `https://www.paypal.com/sdk/js?client-id=${client_ID}`;
      // script.async = true;
      // script.onload = () => {
      //   setSdkReady(true);
      // };
      // document.body.appendChild(script);
      console.log(client_ID);
    };
    // addPayPalScript();
    const importCommande = async () => {
      try {
        dispatch({ type: 'IMPORT_REQUETE' });
        const { data } = await axios.get(`/api/commandes/${commandeId}`, {
          headers: {
            authorization: `Bearer ${infoUtilisateur.token}`,
          },
        });
        dispatch({ type: 'IMPORT_SUCCES', payload: data });
      } catch (err) {
        dispatch({ type: 'IMPORT_ECHEC', payload: getError(err) });
      }
    };
    if (!infoUtilisateur) {
      return navigate('/signin');
    }
    if (
      !commande._id ||
      successLivre ||
      (commande._id && commande._id !== commandeId)
    ) {
      importCommande();
    } else if (!commande.estPaye) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    } else if (successPay) {
      dispatch({ type: 'PAY_RESET' });
      importCommande();
    }
  }, [
    commande,
    infoUtilisateur,
    commandeId,
    navigate,
    successPay,
    successLivre,
  ]);

  const gestionPaiementSucces = (resultatPaiement) => {
    console.log(resultatPaiement);
    paiemantCommande(commande._id, resultatPaiement);
  };

  return loading ? (
    <WidgetChargement />
  ) : error ? (
    <WidgetMessage variant="danger">{error}</WidgetMessage>
  ) : (
    <div>
      <Helmet>
        <title>Commande N° {commandeId}</title>
      </Helmet>
      <h1 className="my-3">Commande N° {commandeId}</h1>
      <Row>
        <Col md={8}>
          <Card>
            <Card.Body>
              <Card.Title>Expédition</Card.Title>
              <Card.Text>
                <strong>Nom:</strong>
                {commande.adresseExpedition.fullName}
                <br />
                <strong>Adresse:</strong>
                {commande.adresseExpedition.adresse},
                {commande.adresseExpedition.ville},{' '}
                {commande.adresseExpedition.codePostal},{' '}
                {commande.adresseExpedition.pays}
              </Card.Text>
              {commande.estLivre ? (
                <WidgetMessage variant="success">
                  Livrée le:{commande.livreLe}
                </WidgetMessage>
              ) : (
                <WidgetMessage variant="danger">Non livrée</WidgetMessage>
              )}
            </Card.Body>
          </Card>

          <Card>
            <Card.Body>
              <Card.Title>Paiement</Card.Title>
              <Card.Text>
                <strong>
                  Methode de Paiement: {commande.methodePaiement}{' '}
                </strong>
              </Card.Text>
              {commande.estPaye ? (
                <WidgetMessage variant="success">
                  Payée le:{commande.payeLe}
                </WidgetMessage>
              ) : (
                <WidgetMessage variant="danger">Non payée</WidgetMessage>
              )}
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Articles</Card.Title>
              <ListGroup variant="flush">
                {commande.elementsCommande.map((element) => (
                  <ListGroupItem key={element.id}>
                    <Row>
                      <Col md={6}>
                        <img
                          src={element.image}
                          alt={element.nom}
                          className="img-fluid rounded img-thumbnail"
                        ></img>
                        <Link to={`/produit/${element.slug}`}>
                          {element.nom}
                        </Link>
                      </Col>
                      <Col md={3}>
                        <span>{element.quantite}</span>
                      </Col>
                      <Col md={3}>{element.prix}</Col>
                    </Row>
                  </ListGroupItem>
                ))}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Récapitulatif de la commande</Card.Title>
              <ListGroup variant="flush">
                <ListGroupItem>
                  <Row>
                    <Col>Articles</Col>
                    <Col>{commande.prixElements.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>Expédition</Col>
                    <Col>{commande.prixExpedition.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>Taxe</Col>
                    <Col>{commande.prixTaxe.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <strong>Total commande</strong>
                    </Col>
                    <Col>
                      <strong>{commande.prixTotal.toFixed(2)} XAF</strong>
                    </Col>
                  </Row>
                </ListGroupItem>
                {!commande.estPaye && commande.methodePaiement === 'PayPal' && (
                  <ListGroupItem>
                    {loadingPay && <WidgetChargement />}
                    {!sdkReady ? (
                      <WidgetChargement />
                    ) : (
                      <PayPalButton
                        amount={commande.prixTotal}
                        onSuccess={gestionPaiementSucces}
                      />
                    )}
                  </ListGroupItem>
                )}
                {commande.estPaye &&
                  !commande.estLivre &&
                  infoUtilisateur.estAdmin && (
                    <ListGroupItem>
                      <Button
                        className="mt-3"
                        onClick={() => marquerCommandeLivre(commande._id)}
                      >
                        Marquer comme livré
                      </Button>
                    </ListGroupItem>
                  )}
                {!commande.estPaye &&
                  commande.methodePaiement === 'mobilemoney' && (
                    <ListGroupItem>
                      <FlutterWave
                        commande={commande}
                        infoUtilisateur={infoUtilisateur}
                      />
                    </ListGroupItem>
                  )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
