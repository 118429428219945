import axios from 'axios';
import React, { useEffect, useReducer, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';
import { Row, Col, Button } from 'react-bootstrap';
import Note from '../composants/Note';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import Produit from '../composants/Produit';
import { LinkContainer } from 'react-router-bootstrap';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_REQUETE':
      return { ...state, loading: true };
    case 'IMPORT_SUCCES':
      return {
        ...state,
        produits: action.payload.produits,
        page: action.payload.page,
        pages: action.payload.pages,
        nombreProduits: action.payload.nombreProduits,
        loading: false,
      };
    case 'IMPORT_ECHEC':
      return { ...state, loading: false, error: action.payload };
    case 'IMPORT_CATEGORIE_REQUETE':
      return { ...state, loadingCategories: true };
    case 'IMPORT_CATEGORIE_ECHEC':
      return {
        ...state,
        loadingCategories: false,
        errorCategories: action.payload,
      };
    default:
      return state;
  }
};

const lesPrix = [
  { nom: '500FCFA à 20000FCFA', valeur: '500-20000' },
  { nom: '20100FCFA à 100000FCFA', valeur: '20100-100000' },
  { nom: '101000FCFA à 500000FCFA', valeur: '101000-500000' },
];

export const lesNotes = [
  { nom: '4etoiles et plus', valeur: 4 },
  { nom: '3etoiles et plus', valeur: 3 },
  { nom: '2etoiles et plus', valeur: 2 },
  { nom: '1etoiles et plus', valeur: 1 },
];

export default function EcranRecherche() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const sp = new URLSearchParams(search);
  console.log(search);

  const categorie = sp.get('categorie') || 'all';
  // const requete = sp.get('requete' || 'all');
  const query = sp.get('query') || 'all';
  const prix = sp.get('prix') || 'all';
  const note = sp.get('note') || 'all';
  const commande = sp.get('commande') || 'newest';
  const page = sp.get('page') || 1;

  const [
    {
      loading,
      error,
      loadingCategories,
      errorCategories,
      produits,
      pages,
      nombreProduits,
    },
    dispatch,
  ] = useReducer(reducer, {
    loading: true,
    loadingCategories: false,
    error: '',
    errorCategories: '',
    produits: [],
  });

  useEffect(() => {
    const importDonnees = async () => {
      dispatch({ type: 'IMPORT_REQUETE' });
      try {
        const { data } = await axios.get(
          `/api/produits/rechercher?page=${page}&query=${query}&categorie=${categorie}&prix=${prix}&note=${note}&commande=${commande}`
          // `/api/produits/rechercher?page=${page}&requete=${requete}&categorie=${categorie}&prix=${prix}&note=${note}&commande=${commande}`
        );
        dispatch({ type: 'IMPORT_SUCCES', payload: data });
      } catch (err) {
        dispatch({ type: 'IMPORT_ECHEC', payload: getError(err) });
      }
    };
    importDonnees();
  }, [categorie, error, commande, page, prix, query, note]);

  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const importCategories = async () => {
      dispatch({ type: 'IMPORT_CATEGOTRIE_REQUETE' });
      try {
        const { data } = await axios.get(`/api/produits/categories`);
        setCategories(data);
      } catch (err) {
        dispatch({
          type: 'IMPORT_CATEGORIE_ECHEC',
          payload: getError(err),
        });
        toast.error(getError(errorCategories));
      }
    };
    importCategories();
  }, [dispatch, errorCategories]);

  const obtenirFiltreUrl = (filtre) => {
    const filtrePage = filtre.page || page;
    const filtreCategorie = filtre.categorie || categorie;
    // const filtreRequete = filtre.requete || requete;
    const filtreQuery = filtre.query || query;
    const filtreNote = filtre.note || note;
    const filtrePrix = filtre.prix || prix;
    const filtreCommande = filtre.commande || commande;

    return `/rechercher?categorie=${filtreCategorie}&quey=${filtreQuery}&prix=${filtrePrix}&note=${filtreNote}&commande=${filtreCommande}&page=${filtrePage}`;
    // return `/rechercher?categorie=${filtreCategorie}&requete=${filtreRequete}&prix=${filtrePrix}&note=${filtreNote}&commande=${filtreCommande}&page=${filtrePage}`;
  };
  return (
    <div>
      <Helmet>
        <title>Rechercher des articles</title>
      </Helmet>
      <Row>
        <Col md={3}>
          <h3>Categories</h3>
          <div>
            <ul>
              <li>
                <Link
                  className={'all' === categorie ? 'text-bold' : ''}
                  to={obtenirFiltreUrl({ categorie: 'all' })}
                >
                  Tout
                </Link>
              </li>
              {categories.map((cat) => (
                <li key={cat}>
                  <Link
                    className={cat === categorie ? 'text-bold' : ''}
                    to={obtenirFiltreUrl({ categorie: cat })}
                  >
                    {cat}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Prix</h3>
            <ul>
              <li>
                <Link
                  className={'all' === prix ? 'text-bold' : ''}
                  to={obtenirFiltreUrl({ prix: 'all' })}
                >
                  Tout
                </Link>
              </li>
              {lesPrix.map((p) => (
                <li key={p.valeur}>
                  <Link
                    className={p.valeur === prix ? 'text-bold' : ''}
                    to={obtenirFiltreUrl({ prix: p.valeur })}
                  >
                    {p.nom}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div>
            <h3>Moyenne des notes clients</h3>
            <ul>
              {lesNotes.map((n) => (
                <li key={n.nom}>
                  <Link
                    to={obtenirFiltreUrl({ note: n.valeur })}
                    className={`${n.valeur}` === `${note}` ? 'text-bold' : ''}
                  >
                    <Note caption={'et plus'} note={n.valeur}></Note>
                  </Link>
                </li>
              ))}
              <li>
                <Link
                  to={obtenirFiltreUrl({ note: 'all' })}
                  className={note === 'all' ? 'text-bold' : ''}
                >
                  <Note caption={'et plus'} note={0}></Note>
                </Link>
              </li>
            </ul>
          </div>
        </Col>

        <Col md={9}>
          {loading ? (
            <WidgetChargement />
          ) : error ? (
            <WidgetMessage variant="danger">{error}</WidgetMessage>
          ) : (
            <>
              <Row className="justify-content-between mb-3">
                <Col md={6}>
                  <div>
                    {nombreProduits === 0 ? 'Aucun' : nombreProduits}{' '}
                    Résultat(s)
                    {query !== 'all' && ' : ' + query}
                    {/* {requete !== 'all' && ' : ' + requete} */}
                    {categorie !== 'all' && ' : ' + categorie}
                    {prix !== 'all' && ' : Prix' + prix}
                    {note !== 'all' && ' : Note' + note + 'et plus'}
                    {query !== 'all' ||
                    // requete !== 'all' ||
                    categorie !== 'all' ||
                    note !== 'all' ||
                    prix !== 'all' ? (
                      <Button
                        variant="light"
                        onClick={() => navigate('/rechercher')}
                      >
                        <i className="fas fa-times-circle"></i>
                      </Button>
                    ) : null}
                  </div>
                </Col>
                <Col className="text-end">
                  Trier par{' '}
                  <select
                    value={commande}
                    onChange={(e) => {
                      navigate(obtenirFiltreUrl({ commande: e.target.value }));
                    }}
                  >
                    <option value="newest">Arrivage</option>
                    <option value="lowest">Prix: Ascendant</option>
                    <option value="highest">Prix: Descendant</option>
                    <option value="toprated">Moyenne des avis</option>
                  </select>
                </Col>
              </Row>
              {produits.length === 0 && (
                <WidgetMessage>Aucun article trouvé</WidgetMessage>
              )}
              <Row>
                {produits.map((produit) => (
                  <Col sm={6} ld={4} className="mb-3" key={produit._id}>
                    <Produit produit={produit}></Produit>
                  </Col>
                ))}
              </Row>

              <div>
                {[...Array(pages).keys()].map((x) => (
                  <LinkContainer
                    key={x + 1}
                    className="mx-1"
                    to={obtenirFiltreUrl({ page: x + 1 })}
                  >
                    <Button
                      className={Number(page) === x + 1 ? 'text-bold' : ''}
                      variant="light"
                    >
                      {x + 1}
                    </Button>
                  </LinkContainer>
                ))}
              </div>
            </>
          )}
        </Col>
      </Row>
    </div>
  );
}
