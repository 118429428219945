import React, { useContext, useReducer, useEffect } from 'react';
import axios from 'axios';
import {
  Card,
  Col,
  Row,
  Button,
  ListGroup,
  ListGroupItem,
} from 'react-bootstrap';
import WidgetChargement from '../composants/WidgetChargement';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet-async';
import { Link, useNavigate } from 'react-router-dom';
import EtapesCaisse from '../composants/EtapesCaisse';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'CREER_REQUETE':
      return { ...state, loading: true };
    case 'CREER_SUCCES':
      return {
        ...state,
        loading: false,
      };
    case 'CREER_ECHEC':
      return { ...state, loading: false };
    default:
      return state;
  }
};
export default function EcranPasserCommande() {
  const navigate = useNavigate();
  const [{ loading, commande }, dispatch] = useReducer(reducer, {
    loading: false,
  });
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { panier, infoUtilisateur } = state;
  const arrondir = (nombre) => Math.round(nombre * 100 + Number.EPSILON) / 100;
  panier.prixElements = arrondir(
    panier.elementsPanier.reduce((a, c) => a + c.quantite * c.prix, 0)
  );
  panier.prixExpedition =
    panier.prixElements > 100 ? arrondir(0) : arrondir(10);
  panier.prixTaxe = arrondir(0.15 * panier.prixElements);
  panier.prixTotal =
    panier.prixElements + panier.prixExpedition + panier.prixTaxe;
  const gestionPasserCommande = async (e) => {
    try {
      dispatch({ type: 'CREER_REQUETE' });
      const { data } = await axios.post(
        '/api/commandes',
        {
          elementsCommande: panier.elementsPanier,
          adresseExpedition: panier.adresseExpedition,
          methodePaiement: panier.methodePaiement,
          prixElements: panier.prixElements,
          prixExpedition: panier.prixExpedition,
          prixTaxe: panier.prixTaxe,
          prixTotal: panier.prixTotal,
        },
        { headers: { Authorization: `Bearer ${infoUtilisateur.token}` } }
      );
      ctxDispatch({ type: 'PANIER_VIDE' });
      localStorage.removeItem('elementsPanier');
      dispatch({ type: 'CREER_SUCCES', payload: data });
      toast.success('Nouvelle commande créee avec succès.');
      console.log(data);
      navigate(`/commande/${data._id}`);
    } catch (err) {
      dispatch({ type: 'CREER_ECHEC' });
      toast.error(getError(err));
    }
  };
  useEffect(() => {
    if (!panier.methodePaiement) {
      navigate('/paiement');
    }
  }, [panier, navigate]);

  return (
    <div>
      <EtapesCaisse etape1 etape2 etape3 etape4 />
      <Helmet>
        <title>Prévisualiser la commande</title>
      </Helmet>
      <h1 className="my-3">Prévisualiser la commande</h1>
      <Row>
        <Col md={8}>
          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Expédition</Card.Title>
              <Card.Text>
                <strong>Nom:</strong> {panier.adresseExpedition.fullName}
                <br />
                <strong>Adresse:</strong> {panier.adresseExpedition.adresse},{' '}
                {panier.adresseExpedition.ville},{' '}
                {panier.adresseExpedition.codePostal},{' '}
                {panier.adresseExpedition.pays}
              </Card.Text>
              <Link to="/expedition">Edit</Link>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Paiement</Card.Title>
              <Card.Text>
                <strong>Methode de Paiement:</strong> {panier.methodePaiement}
              </Card.Text>
              <Link to="/paiement">Editer</Link>
            </Card.Body>
          </Card>

          <Card className="mb-3">
            <Card.Body>
              <Card.Title>Articles</Card.Title>
              <ListGroup variant="flush">
                {panier.elementsPanier &&
                  panier.elementsPanier.map((element) => (
                    <ListGroupItem key={element._id}>
                      <Row className="align-items-center">
                        <Col md={6}>
                          <img
                            src={element.image}
                            alt={element.nom}
                            className="image-fluid rounded img-thumbnail"
                          ></img>
                          <Link to={`/produit/${element.slug}`}>
                            {element.nom}
                          </Link>
                        </Col>
                        <Col md={3}>
                          <span>{element.quantite}</span>
                        </Col>
                        <Col md={3}>{element.prix}</Col>
                      </Row>
                    </ListGroupItem>
                  ))}
              </ListGroup>
              <Link to="/panier">Editer</Link>
            </Card.Body>
          </Card>
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <Card.Title>Récapitulatif de la commande</Card.Title>
              <ListGroup variant="flush">
                <ListGroupItem>
                  <Row>
                    <Col>Articles</Col>
                    <Col>{panier.prixElements.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>Expedition</Col>
                    <Col>{panier.prixExpedition.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>Taxe</Col>
                    <Col>{panier.prixTaxe.toFixed(2)} XAF</Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <Row>
                    <Col>
                      <strong>Total de la commande</strong>
                    </Col>
                    <Col>
                      <strong>{panier.prixTotal.toFixed(2)} XAF</strong>
                    </Col>
                  </Row>
                </ListGroupItem>
                <ListGroupItem>
                  <div className="d-grid">
                    <Button
                      type="button"
                      onClick={gestionPasserCommande}
                      disabled={panier.elementsPanier.length === 0}
                    >
                      Passer la commande
                    </Button>
                  </div>
                  {loading && <WidgetChargement />}
                </ListGroupItem>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
