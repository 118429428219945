import React, { useContext, useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import EtapesCaisse from '../composants/EtapesCaisse';
import { Store } from '../Store';

export default function EcranMethodePaiement() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    panier: { adresseExpedition, methodePaiement },
  } = state;
  const [nomMethodePaiement, setNomMethodePaiement] = useState(
    methodePaiement || 'PayPal'
  );
  useEffect(() => {
    if (!adresseExpedition.adresse) {
      navigate('/expedition');
    }
  }, [adresseExpedition, navigate]);

  const gestionSoumission = (e) => {
    e.preventDefault();
    ctxDispatch({
      type: 'SAUVEGARDER_METHODE_PAIEMENT',
      payload: nomMethodePaiement,
    });
    localStorage.setItem('methodePaiement', nomMethodePaiement);
    navigate('/passercommande');
  };
  return (
    <div>
      <EtapesCaisse etape1 etape2 etape3 />
      <div className="container small-container">
        <Helmet>
          <title>Méthode de Paiement</title>
        </Helmet>
        <h1 className="my-3">Méthode de Paiment</h1>
        <Form onSubmit={gestionSoumission}>
          <div className="mb-3">
            <Form.Check
              type="radio"
              id="PayPal"
              label="PayPal"
              value="PayPal"
              checked={nomMethodePaiement === 'PayPal'}
              onChange={(e) => setNomMethodePaiement(e.target.value)}
            />
          </div>
          {/* <div className="mb-3">
            <Form.Check
              type="radio"
              id="Stripe"
              label="Stripe"
              value="Stripe"
              disabled
              checked={nomMethodePaiement === 'Stripe'}
              onChange={(e) => setNomMethodePaiement(e.target.value)}
            />
          </div> */}
          <div className="mb-3">
            <Form.Check
              type="radio"
              id="mobilemoney"
              label="mobilemoney"
              value="mobilemoney"
              checked={nomMethodePaiement === 'mobilemoney'}
              onChange={(e) => setNomMethodePaiement(e.target.value)}
            />
          </div>
          <div className="mb-3">
            <Button type="submit">Continuer</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}
