import React from 'react';

import { Spinner } from 'react-bootstrap';

function WidgetChargement() {
  return (
    <Spinner animation="border" role="status">
      <span className="visually-hidden">Chargement...</span>
    </Spinner>
  );
}

export default WidgetChargement;
