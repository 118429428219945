import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import banner from '../css/banner.css';

export default function Banner() {
  const settings = {
    className: '',
    // dots: true,
    infinite: true,
    // centerMode: true,
    speed: 700,
    fade: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 20000,
    arrows: false,
    // variableWidth: true,
    // initialSlide: 0,
    // arrows: false,

    // responsive: [
    //   {
    //     breakpoint: 600,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    //   {
    //     breakpoint: 480,
    //     settings: {
    //       slidesToShow: 1,
    //       slidesToScroll: 1,
    //     },
    //   },
    // ],
  };

  return (
    <Slider {...settings}>
      <div>
        <div
          className="banner"
          style={{
            backgroundImage: 'url(images/client_satisfait.jpg)',
            backgroundPosition: '0% 7% ',
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
          }}
        >
          <div>
            <div className="banner-container">
              <div className="bannerText-container">
                <h2>Bienvenue chez Yves-Commerce</h2>
                <h3>Le leader du commerce électronique en ligne</h3>
                <h4>le plus grand magasin d'électronique chez vous</h4>
              </div>
            </div>
            <div className="banner-fadeBottom"></div>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div
            className="banner"
            style={{
              backgroundImage: 'url(images/paiement-distant.jpg)',
              backgroundPosition: '0% 7% ',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <div className="banner-container">
              <div className="bannerText-container-1">
                <h2>Bienvenue chez Yves-Commerce</h2>
                <h3>Le leader du commerce électronique en ligne</h3>
                <h4>le plus grand magasin d'électronique chez vous</h4>
              </div>
            </div>
            <div className="banner-fadeBottom"></div>
          </div>
        </div>
      </div>
    </Slider>
  );
}
