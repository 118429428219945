import React, { useContext, useEffect, useReducer } from 'react';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { Row, Col, ListGroup, Card, Badge, Button } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import Note from '../composants/Note';
import WidgetMessage from '../composants/WidgetMessage';
import WidgetChargement from '../composants/WidgetChargement';
import { getError } from '../utils';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return { ...state, produit: action.payload, loading: false };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function EcranProduit() {
  const navigate = useNavigate();
  const params = useParams();
  const { slug } = params;

  const [{ loading, error, produit }, dispatch] = useReducer(reducer, {
    produit: [],
    loading: true,
    error: '',
  });

  useEffect(() => {
    const importerDonnees = async () => {
      dispatch({ type: 'FETCH_REQUEST' });
      try {
        const resultat = await axios.get(`/api/produits/slug/${slug}`);
        dispatch({ type: 'FETCH_SUCCESS', payload: resultat.data });
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: getError(error) });
      }
    };
    importerDonnees();
  }, [slug]);

  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { panier } = state;

  const gestionAjoutPanier = async () => {
    const elementExiste = panier.elementsPanier.find(
      (x) => x._id === produit._id
    );
    const quantite = elementExiste ? elementExiste.quantite + 1 : 1;
    const { data } = await axios.get(`/api/produits/${produit._id}`);
    if (data.nombreEnStock < quantite) {
      window.alert('Désolé. Ce produit est hors de stock');
      return;
    }
    ctxDispatch({
      type: 'PANIER_AJOUT_ELEMENT',
      payload: { ...produit, quantite },
    });
    navigate('/panier');
  };

  return loading ? (
    <WidgetChargement />
  ) : error ? (
    <WidgetMessage variant="danger">{error}</WidgetMessage>
  ) : (
    <div>
      <Row>
        <Col md={6}>
          <img src={produit.image} alt={produit.nom} className="img-large" />
        </Col>
        <Col md={3}>
          <ListGroup variant="flush">
            <ListGroup.Item>
              <Helmet>
                <title>{produit.nom}</title>
              </Helmet>
              <h1>{produit.nom}</h1>
            </ListGroup.Item>
            <ListGroup.Item>
              <Note
                note={produit.note}
                nombreDavis={produit.nombreDavis}
              ></Note>
            </ListGroup.Item>
            <ListGroup.Item>Prix: {produit.prix}</ListGroup.Item>
            <ListGroup.Item>
              Description: <p>{produit.description}</p>
            </ListGroup.Item>
          </ListGroup>
        </Col>
        <Col md={3}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <Row>
                    <Col>Prix:</Col>
                    <Col>{produit.prix}</Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Statut:</Col>
                    <Col>
                      {produit.nombreEnStock > 0 ? (
                        <Badge bg="success">En stock</Badge>
                      ) : (
                        <Badge bg="danger">Indisponible</Badge>
                      )}
                    </Col>
                  </Row>
                </ListGroup.Item>
                {produit.nombreEnStock > 0 && (
                  <ListGroup.Item>
                    <div className="d-grid">
                      <Button onClick={gestionAjoutPanier} variant="primary">
                        Ajouter au panier
                      </Button>
                    </div>
                  </ListGroup.Item>
                )}
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default EcranProduit;
