import React, { useContext, useEffect, useState } from 'react';
import { Routes, Route, Link, BrowserRouter } from 'react-router-dom';
import {
  Nav,
  Navbar,
  Container,
  Badge,
  NavDropdown,
  Button,
} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { LinkContainer } from 'react-router-bootstrap';
import EcranAccueil from './ecrans/EcranAccueil';
import EcranProduit from './ecrans/EcranProduit';
import { Store } from './Store';
import EcranPanier from './ecrans/EcranPanier';
import EcranConnexion from './ecrans/EcranConnexion';
import EcranAdresseExpedition from './ecrans/EcranAdresseExpedition';
import EcranInscription from './ecrans/EcranInscription';
import EcranMethodePaiement from './ecrans/EcranMethodePaiement';
import EcranPasserCommande from './ecrans/EcranPasserCommande';
import EcranCommande from './ecrans/EcranCommande';
import EcranHistoriqueCommande from './ecrans/EcranHistoriqueCommande';
import EcranProfileUtilisateur from './ecrans/EcranProfileUtilisateur';
import { getError } from './utils';
import axios from 'axios';
import EcranRecherche from './ecrans/EcranRecherche';
import EcranDashboard from './ecrans/EcranDashboard';
import RouteProtection from './composants/RouteProtection';
import RouteAdministrateur from './composants/RouteAdministrateur';
import EcranEditionProduit from './ecrans/EcranEditionProduit';
import BarreRecherche from './composants/BarreRecherche';
import EcranListeArticles from './ecrans/EcranListeArticles';
import EcranStatistiques from './ecrans/EcranStatistiques';
import EcranListeCommande from './ecrans/EcranListeCommande';
import EcranListeUtilisateurs from './ecrans/EcranListeUtilisateurs';
import EcranMajUtilisateur from './ecrans/EcranMajUtilisateur';
import EcranModifierUtilisateur from './ecrans/EcranModifierUtilisateur';
import EcranPaginationListeArticles from './ecrans/EcranPaginationListeArticles';

function App() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { panier, infoUtilisateur } = state;
  const gestionDeconnexion = () => {
    ctxDispatch({ type: 'DECONNEXION' });
    localStorage.removeItem('infoUtilisateur');
    localStorage.removeItem('adresseExpedition');
    localStorage.removeItem('methodePaiement');
    window.location.href = '/signin';
  };
  const date = new Date();
  const annee = date.getFullYear();

  const [barreLateraleVisible, setBarreLateraleVisible] = useState(false);
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    const importCategories = async () => {
      try {
        const { data } = await axios.get(`/api/produits/categories`);
        setCategories(data);
      } catch (err) {
        toast.error(getError(err));
      }
    };
    importCategories();
  }, []);

  return (
    <div>
      <BrowserRouter>
        <div
          className={
            barreLateraleVisible
              ? 'd-flex flex-column site-container cont-actif'
              : 'd-flex flex-column site-container'
          }
        >
          <ToastContainer position="top-right" limit={1} />
          <header>
            <Navbar
              className="header-padding"
              bg="dark"
              variant="dark"
              expand="lg"
            >
              <Container>
                <Button
                  variant="dark"
                  className="mx-2"
                  onClick={() => setBarreLateraleVisible(!barreLateraleVisible)}
                >
                  <img src="/images/icone-categories.png" alt=""></img>
                  {/* <i className="fas fa-bars"></i> */}
                </Button>
                <LinkContainer to="/">
                  <Navbar.Brand>
                    <span>Yves-Commerce</span>
                  </Navbar.Brand>
                </LinkContainer>
                <Navbar.Toggle aria-controls="basic-navbar-nav"></Navbar.Toggle>
                <Navbar.Collapse id="basic-navbar-nav">
                  <BarreRecherche />
                  <Nav className="d-flex me-auto w-100 justify-content-end">
                    <Link to="/panier" className="nav-link">
                      <span>
                        Panier
                        {panier.elementsPanier.length > 0 && (
                          <Badge pill bg="success" className="mx-1 bg-green">
                            {panier.elementsPanier.reduce(
                              (a, c) => a + c.quantite,
                              0
                            )}
                          </Badge>
                        )}
                      </span>
                    </Link>
                    {infoUtilisateur ? (
                      <NavDropdown
                        title={infoUtilisateur.nom}
                        id="basic-nav-dropdown"
                      >
                        {infoUtilisateur && infoUtilisateur.estAdmin ? (
                          <LinkContainer to="/admin/dashboard">
                            <NavDropdown.Item>Dashboard</NavDropdown.Item>
                          </LinkContainer>
                        ) : (
                          <LinkContainer to="/profile">
                            <NavDropdown.Item>
                              Profile Utilisateur
                            </NavDropdown.Item>
                          </LinkContainer>
                        )}
                        <LinkContainer to="/miennes">
                          <NavDropdown.Item>
                            Historique Commande
                          </NavDropdown.Item>
                        </LinkContainer>
                        <NavDropdown.Divider />
                        <Link
                          className="dropdown-item"
                          to="#deconnexion"
                          onClick={gestionDeconnexion}
                        >
                          Deconnexion
                        </Link>
                      </NavDropdown>
                    ) : (
                      <Link className="nav-link" to="/signin">
                        Connexion
                      </Link>
                    )}
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </header>
          <div
            className={
              barreLateraleVisible
                ? 'nav-actif navbar-laterale d-flex justify-content-between flex-wrap flex-column'
                : 'navbar-laterale d-flex justify-content-between flex-wrap flex-column'
            }
          >
            <Nav className="flex-column text-white w-100 p-2">
              <Nav.Item className="mb-4 mt-3">
                <strong>Catégories</strong>
              </Nav.Item>
              {categories.map((categorie) => (
                <Nav.Item key={categorie}>
                  <LinkContainer
                    to={`/rechercher?categorie=${categorie}`}
                    onClick={() => setBarreLateraleVisible(false)}
                  >
                    <Nav.Link>{categorie}</Nav.Link>
                  </LinkContainer>
                </Nav.Item>
              ))}
            </Nav>
          </div>
          <main>
            <Routes>
              <Route path="/" element={<EcranAccueil />} />
              <Route
                path="/article/page/:numeroPage"
                element={<EcranAccueil />}
              />
            </Routes>
            <Container className="mt-4">
              <Routes>
                <>
                  <Route path="/produit/:slug" element={<EcranProduit />} />
                  <Route path="/panier" element={<EcranPanier />} />
                  <Route path="/rechercher" element={<EcranRecherche />} />
                  <Route path="/signin" element={<EcranConnexion />} />
                  <Route path="/inscription" element={<EcranInscription />} />
                  <Route
                    path="/profile"
                    element={
                      <RouteProtection>
                        <EcranProfileUtilisateur />
                      </RouteProtection>
                    }
                  />
                  <Route
                    path="/admin/listeutilisateurs/:id/edit"
                    element={
                      <RouteProtection>
                        <EcranProfileUtilisateur />
                      </RouteProtection>
                    }
                  />
                  <Route
                    path="/expedition"
                    element={<EcranAdresseExpedition />}
                  />
                  <Route path="/paiement" element={<EcranMethodePaiement />} />
                  <Route
                    path="/passercommande"
                    element={<EcranPasserCommande />}
                  />
                  <Route
                    path="/commande/:id"
                    element={
                      <RouteProtection>
                        <EcranCommande />
                      </RouteProtection>
                    }
                  />
                  <Route
                    path="/miennes"
                    element={
                      <RouteProtection>
                        <EcranHistoriqueCommande />
                      </RouteProtection>
                    }
                  />
                  {/* Routes Admin */}

                  <Route path="/admin/dashboard" element={<EcranDashboard />} />
                  <Route
                    path="/admin/dashboard/article/:numeroPage"
                    element={<EcranDashboard />}
                  />
                  <Route
                    path="/admin/dashboard/commande/:numeroPage"
                    element={<EcranDashboard />}
                  />

                  {/* <Route
                    path="/admin/dashboard/article/pagination"
                    element={<EcranPaginationListeArticles />}
                  />
                  <Route
                    path="/admin/dashboard/article/pagination/:numeroPage"
                    element={<EcranPaginationListeArticles />}
                  /> */}
                  <Route
                    path="/admin/dashboard/utilisateurs"
                    element={<EcranListeUtilisateurs />}
                  />
                  <Route
                    path="/admin/dashboard/utilisateurs/:id/edit"
                    element={<EcranModifierUtilisateur />}
                  />
                  <Route
                    path="/admin/dashboard/article/edit/:article"
                    element={
                      <RouteAdministrateur>
                        <EcranEditionProduit />
                      </RouteAdministrateur>
                    }
                  />
                  <Route
                    path="/admin/dashboard/commandes"
                    element={<EcranListeCommande />}
                  />
                  <Route
                    path="/admin/dashboard/statistiques"
                    element={<EcranStatistiques />}
                  />
                </>
              </Routes>
            </Container>
          </main>

          <footer>
            <div className="text-center">
              Tous droits reservés &copy; {annee}
            </div>
            <div className="text-center">By Yves Ayong </div>
          </footer>
        </div>
      </BrowserRouter>
    </div>
  );
}

export default App;
