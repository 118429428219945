import React from 'react';
import { Col, Row } from 'react-bootstrap';

export default function EtapesCaisse(props) {
  return (
    <Row className="etapes-caisse">
      <Col className={props.etape1 ? 'actif' : ''}>Connexion</Col>
      <Col className={props.etape2 ? 'actif' : ''}>Expedition</Col>
      <Col className={props.etape3 ? 'actif' : ''}>Paiement</Col>
      <Col className={props.etape4 ? 'actif' : ''}>PasserCommande</Col>
    </Row>
  );
}
