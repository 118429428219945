import axios from 'axios';
import { Action } from 'history';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Store } from '../Store';
import { getError } from '../utils';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_COMMANDES_REQUETE':
      return { ...state, loadingCommandes: true };
    case 'IMPORT_COMMANDES_SUCCES':
      return {
        ...state,
        loadingCommandes: false,
        lesCommandes: action.payload,
      };
    case 'IMPORT_COMMANDES_ECHEC':
      return {
        ...state,
        loadingCommandes: false,
        errorCommandes: action.payload,
      };
  }
};

export default function EcranStatistiques() {
  const { state, dispatch: ctxDispatch } = useContext(Store);

  const { infoUtilisateur } = state;

  const [
    { loadingCommandes, lesCommandes, errorCommandes },
    dispatch,
  ] = useReducer(reducer, {
    loadingCommandes: false,
    lesCommandes: [],
    errorCommandes: '',
  });

  const donneesCommandes = [
    {
      mois: 'janvier',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 1 && a + c,
        0
      ),
      ventes: 234000,
    },
    {
      mois: 'Février',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 2 && a + c,
        0
      ),
      ventes: 154000,
    },
    {
      mois: 'Mars',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 3 && a + c,
        0
      ),
      ventes: 399000,
    },
    {
      mois: 'Avril',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 4 && a + c,
        0
      ),
      ventes: 310000,
    },
    {
      mois: 'Mai',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 5 && a + c,
        0
      ),
      ventes: 84000,
    },
    {
      mois: 'Juin',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 6 && a + c,
        0
      ),
      ventes: 15000000,
    },
    {
      mois: 'Juillet',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 7 && a + c,
        0
      ),
      ventes: 294000,
    },
    {
      mois: 'Août',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 8 && a + c,
        0
      ),
      ventes: 241000,
    },
    {
      mois: 'Septembre',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 9 && a + c,
        0
      ),
      ventes: 120000,
    },
    {
      mois: '0ctobre',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 10 && a + c,
        0
      ),
      ventes: 1300000,
    },
    {
      mois: 'Novembre',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 11 && a + c,
        0
      ),
      ventes: 174000,
    },
    {
      mois: 'Décembre',
      commandes: lesCommandes.reduce(
        (a, c) => c.createdAt.getMonth() === 12 && a + c,
        0
      ),
      ventes: 900000,
    },
  ];

  useEffect(() => {
    const importDonneesCommandes = async () => {
      try {
        dispatch({ type: 'IMPORT_COMMANDES_REQUETE' });
        const { data } = await axios.get('/api/commandes', {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'IMPORT_COMMANDES_SUCCES', payload: data });
      } catch (error) {
        dispatch({ type: 'IMPORT_COMMANDES_ECHEC', payload: getError(error) });
      }
    };
    importDonneesCommandes();
  }, [infoUtilisateur]);

  return <div>EcranStatistiques</div>;
}
