import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';
import { Store } from '../Store';

export default function RouteAdministrateur({ children }) {
  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  return infoUtilisateur && infoUtilisateur.estAdmin ? (
    children
  ) : (
    <Navigate to="signin" />
  );
}
