import React, { useReducer, useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { Button, Container, Form } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import { getError } from '../utils';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';

const reducer = (state, action) => {
  switch (action.type) {
    case 'PRODUIT_MODIFIER_REQUETE':
      return { ...state, loadingModifier: true };
    case 'PRODUIT_MODIFIER_SUCCES':
      return {
        ...state,
        loadingModifier: false,
        succesModifier: true,
        produitModifie: action.payload.produitModifie,
        messageSucces: action.payload.messageSucces,
      };
    case 'PRODUIT_MODIFIER_ECHEC':
      return {
        ...state,
        loadingModifier: false,
        messageEchec: action.payload.messageEchec,
      };
    case 'PRODUIT_MODIFIER_RESET':
      return { ...state, produitModifie: {} };
    case 'PRODUIT_DETAILS_REQUETE':
      return { ...state, loadingDetails: true };
    case 'PRODUIT_DETAILS_SUCCES':
      return {
        ...state,
        loadingDetails: false,
        produitDetails: action.payload,
      };
    case 'PRODUIT_DETAILS_ECHEC':
      return { ...state, loadingDetails: false, errorDetails: action.payload };
    case 'TELECHARGEMENT_FICHIER_REQUETE':
      return { ...state, loadingFile: true };
    case 'TELECHARGEMENT_FICHIER_SUCCES':
      return { ...state, loadingFile: false };
    case 'TELECHARGEMENT_FICHIER_ECHEC':
      return { ...state, loadingFile: false, errorFile: action.payload };
    default:
      return state;
  }
};
export default function EcranEditionProduit() {
  const produitId = useParams();

  const navigate = useNavigate();

  const [nom, setNom] = useState('');
  const [slug, setSlug] = useState('');
  const [image, setImage] = useState('');
  const [brand, setBrand] = useState('');
  const [categorie, setCategorie] = useState('');
  const [description, setDescription] = useState('');
  const [prix, setPrix] = useState(0);
  const [nombreEnStock, setNombreEnStock] = useState(0);
  const [uploading, setUploading] = useState(false);

  const [
    {
      loadingModifier,
      loadingDetails,
      loadingFile,
      succesModifier,
      produitModifie,
      messageSucces,
      produitDetails,
      messageEchec,
      errorDetails,
      errorFile,
    },
    dispatch,
  ] = useReducer(reducer, {
    loadingModifier: false,
    loadingDetails: false,
    loadingFile: false,
    succesModifier: false,
    produitModifie: {},
    messageSucces: '',
    produitDetails: {},
    messageEchec: '',
    errorDetails: '',
    errorFile: '',
  });

  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  useEffect(() => {
    // if (!infoUtilisateur.estAdmin) {
    //   navigate('/signin');
    // }
    if (succesModifier) {
      dispatch({ type: 'PRODUIT_MODIFIER_RESET' });
      navigate('/admin/dashboard');
    }
    const importDetailsProduit = async () => {
      try {
        dispatch({ type: 'PRODUIT_DETAILS_REQUETE' });
        const { data } = await axios.get(`/api/produits/${produitId.article}`, {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'PRODUIT_DETAILS_SUCCES', payload: data });
      } catch (error) {
        dispatch({ type: 'PRODUIT_DETAILS_ECHEC', payload: error.message });
      }
    };

    if (!produitDetails.nom || produitDetails._id !== produitId.article) {
      importDetailsProduit();
    } else {
      setNom(produitDetails.nom);
      setSlug(produitDetails.slug);
      setImage(produitDetails.image);
      setBrand(produitDetails.brand);
      setCategorie(produitDetails.categorie);
      setDescription(produitDetails.description);
      setPrix(produitDetails.prix);
      setNombreEnStock(produitDetails.nombreEnStock);
    }
  }, [
    dispatch,
    navigate,
    produitId,
    produitDetails,
    succesModifier,
    infoUtilisateur,
  ]);

  const gestionUploadImage = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    try {
      dispatch({ type: 'TELECHARGEMENT_FICHIER_REQUETE' });
      const config = { headers: { 'Content-Type': 'multipart/form-data' } };
      const { data } = await axios.post('/api/upload', formData, config);
      dispatch({ type: 'TELECHARGEMENT_FICHIER_SUCCES' });
      setImage(data);
      console.log(image);
    } catch (err) {
      toast.error(getError(err));
      dispatch({
        type: 'TELECHARGEMENT_FICHIER_ECHEC',
        payload: getError(err),
      });
    }
  };

  const gestionEnvoi = (e) => {
    e.preventDefault();
    const modifierProduit = async () => {
      try {
        dispatch({ type: 'PRODUIT_MODIFIER_REQUETE' });
        const { data } = await axios.put(
          `/api/produits/${produitId.article}/edit`,
          {
            nom,
            slug,
            image,
            brand,
            categorie,
            description,
            prix,
            nombreEnStock,
          },
          { headers: { Authorization: `Bearer ${infoUtilisateur.token}` } }
        );
        dispatch({ type: 'PRODUIT_MODIFIER_SUCCES', payload: data });
        toast.success(messageSucces);
      } catch (error) {
        dispatch({ type: 'PRODUIT_MODIFIER_ECHEC', payload: getError(error) });
        toast.error(messageEchec);
      }
    };
    modifierProduit();
  };

  return (
    <>
      <Helmet>
        <title>Editer l'article</title>
      </Helmet>
      <Container>
        <Link to="/admin/dashboard" className="btn bg-light mt-5">
          Retour à la liste d'articles
        </Link>
      </Container>
      <div className="container small-container">
        {loadingModifier && <WidgetChargement />}
        {messageEchec && (
          <WidgetMessage variant="danger">{messageEchec}</WidgetMessage>
        )}
        {loadingDetails && <WidgetChargement />}
        {errorDetails ? (
          <WidgetMessage variant="danger">{errorDetails}</WidgetMessage>
        ) : (
          <>
            <h3 className="mt-5 mb-4"> Editer l'article</h3>
            <Form onSubmit={gestionEnvoi}>
              <Form.Group controlId="titre" className="mt-4">
                <Form.Label> Nom </Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Entrez le nom de l'article"
                  value={nom}
                  onChange={(e) => setNom(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="slug" className="mt-4">
                <Form.Label> Slug </Form.Label>
                <Form.Control
                  type="name"
                  placeholder="Entrez le slug de l'article"
                  value={slug}
                  onChange={(e) => setSlug(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="image" className="my-4">
                <Form.Label>Image d'illutration de l'article</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Entrez l'url de l'image"
                  value={image}
                  onChange={(e) => setImage(e.target.value)}
                ></Form.Control>
                <Form.Control
                  id="fichier-image"
                  type="file"
                  label="choisir une image"
                  custom
                  onChange={gestionUploadImage}
                ></Form.Control>
                {loadingFile && <WidgetChargement />}
              </Form.Group>

              <Form.Group controlId="brand" className="mt-4">
                <Form.Label> Marque </Form.Label>
                <Form.Control
                  type="text"
                  name="brand"
                  placeholder="Entrez la marque de l'article"
                  value={brand}
                  onChange={(e) => setBrand(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="categorie" className="mt-4">
                <Form.Label> Categorie </Form.Label>
                <Form.Control
                  type="text"
                  name="categorie"
                  placeholder="Entrez la categorie de l'article"
                  value={categorie}
                  onChange={(e) => setCategorie(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="description" className="mt-4">
                <Form.Label> Description </Form.Label>
                <Form.Control
                  type="text"
                  name="description"
                  as="textarea"
                  rows="5"
                  placeholder="Entrez la description de l'article"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="prix" className="mt-4">
                <Form.Label> Prix </Form.Label>
                <Form.Control
                  type="number"
                  name="prix"
                  value={prix}
                  onChange={(e) => setPrix(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="nombreEnStock" className="mt-4">
                <Form.Label> Nombre en stock </Form.Label>
                <Form.Control
                  type="number"
                  name="prix"
                  value={nombreEnStock}
                  onChange={(e) => setNombreEnStock(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type="submit" className="bouton_normal btn mt-5 mb-5">
                Mettre l'article à jour
              </Button>
            </Form>
          </>
        )}
      </div>
    </>
  );
}
