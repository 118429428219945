import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DashboardMobileSidebar from '../composants/DashboardMobileSidebar';
import ProfileAdministrateur from '../composants/ProfileAdministrateur';
import ecrandashboard from '../css/ecrandashboard.css';
import EcranListeArticles from '../ecrans/EcranListeArticles';
import { Store } from '../Store';
import EcranListeUtilisateurs from './EcranListeUtilisateurs';
import EcranListeCommande from './EcranListeCommande';
import EcranStatistiques from './EcranStatistiques';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default function EcranDashboard() {
  const naviguer = useNavigate();
  const { state, dispatch } = useContext(Store);
  const { infoUtilisateur } = state;
  const [etendre, setEtendre] = useState(false);
  const [fermer, setFermer] = useState('');
  const [hideElement, setHideElement] = useState('');
  const [ouvrirMenuMobile, setOuvrirMenuMobile] = useState('');
  const [fermerMenuMobile, setFermerMenuMobile] = useState('');
  const [imageDeconnexion, setImageDeconnexion] = useState('gris');

  const [switchScreen, setSwitchScreen] = useState('');
  const reduction = () => {
    setEtendre(true);
    setFermer('fermer');
    setHideElement('hide-element');
  };
  const extension = () => {
    setEtendre(false);
    setFermer('');
    setHideElement('');
  };

  const extensionMenuMobile = () => {
    if (!ouvrirMenuMobile) {
      setOuvrirMenuMobile('ouvrir-mobile');
      setFermerMenuMobile('');
    }
  };

  // commuter entre les sections

  const handleSwitchScreen = (stateScreen) => {
    localStorage.removeItem('switchscreen');
    setSwitchScreen(stateScreen);
    localStorage.setItem('switchscreen', stateScreen);
    naviguer('/admin/dashboard');
  };

  useEffect(() => {
    setSwitchScreen(localStorage.getItem('switchscreen'));
  }, [switchScreen]);

  // Retour à l'écran d'accueil

  const retourAccueil = () => {
    naviguer('/');
  };

  // deconnexion

  const deconnexion = () => {
    dispatch({ type: 'DECONNEXION_UTILISATEUR' });
    localStorage.removeItem('infoUtilisateur');
    localStorage.removeItem('switchscreen');
    naviguer('/');
  };

  const [lesPages, setLesPages] = useState('');
  const [laPage, setLaPage] = useState('');

  const getPages = (pges = '') => {
    setLesPages(pges);
  };

  const getPage = (pge = '') => {
    setLaPage(pge);
  };

  return (
    <div className="conteneur">
      <div className={`menu-mobile ml-3`} onClick={extensionMenuMobile}>
        Menu
      </div>
      <DashboardMobileSidebar
        ouvrir={ouvrirMenuMobile}
        fermer={fermerMenuMobile}
        fonctionOuvrir={setOuvrirMenuMobile}
        fonctionFermer={setFermerMenuMobile}
        switchscreen={switchScreen}
        setSwitchScreen={setSwitchScreen}
      />
      <div className={`conteneur-gauche ${fermer} `}>
        <div className={`enveloppe`}>
          <div className={`zone-titre ${hideElement}`}>
            <>
              <div className={`titre `}>Tableau de bord</div>
              <div className={`sous-titre `}>Yves-commerce</div>
              <img
                src="/images/dashboard.png"
                alt=""
                className={`icone-titre ${hideElement}`}
                onClick={extension}
              ></img>
            </>
            <>
              {etendre && (
                <img
                  src="/images/suivant.png"
                  alt=""
                  className="etendre-sidebar"
                  onClick={extension}
                ></img>
              )}
              {!etendre && (
                <img
                  src="/images/precedent.png"
                  alt=""
                  className="reduire-sidebar"
                  onClick={reduction}
                ></img>
              )}
            </>
          </div>

          <div className={`zone-element ${hideElement}`}>
            <div className={`element ${hideElement}`} onClick={retourAccueil}>
              <img
                src="/images/accueil.png"
                alt=""
                className="element-picture"
              ></img>
              <span className={`element-text`}>Accueil</span>
            </div>
            <div
              className={`element ${hideElement}`}
              onClick={() => handleSwitchScreen('profil')}
            >
              <img
                src="/images/profil.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('profil')}
              ></img>
              <span className={`element-text `}>Profil</span>
            </div>
            <div
              className={`element ${hideElement}`}
              onClick={() => handleSwitchScreen('articles')}
            >
              <img
                src="/images/article.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('articles')}
              ></img>
              <span className={`element-text `}>Articles</span>
            </div>
            <div
              className={`element ${hideElement}`}
              onClick={() => handleSwitchScreen('utilisateurs')}
            >
              <img
                src="/images/utilisateurs.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('utilisateurs')}
              ></img>
              <span className={`element-text`}>Utilisateurs</span>
            </div>
            <div
              className={`element ${hideElement}`}
              onClick={() => handleSwitchScreen('commandes')}
            >
              <img
                src="/images/commandes.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('commandes')}
              ></img>
              <span className={`element-text`}>Commandes</span>
            </div>
          </div>
          <div className={`zone-deconnexion ${hideElement}`}>
            <hr className={`ligne-deconnexion ${hideElement}`} />
            <div className="element-deconnexion" onClick={deconnexion}>
              {
                <img
                  onMouseOver={() => setImageDeconnexion('rouge')}
                  onMouseLeave={() => setImageDeconnexion('gris')}
                  src={`/images/deconnexion_${imageDeconnexion}.png`}
                  alt=""
                ></img>
              }
              <span className={`element-text `}>Déconnexion</span>
            </div>
          </div>
        </div>
      </div>
      <div className={`conteneur-droit ${fermer}`}>
        {switchScreen === 'commandes' && <EcranListeCommande />}
        {switchScreen === 'utilisateurs' && <EcranListeUtilisateurs />}
        {switchScreen === 'articles' && <EcranListeArticles />}
        {switchScreen === 'profil' && <ProfileAdministrateur />}
        {(switchScreen === '' || !localStorage.getItem('switchscreen')) && (
          <EcranListeArticles lespages={getPages} lapage={getPage} />
        )}
      </div>
      <></>
    </div>
  );
}
