import React, { useState, useContext, useReducer, useEffect } from 'react';
import { Button, FormGroup, FormLabel, FormControl } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import { getError } from '../utils';
import axios from 'axios';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';

const reducer = (state, action) => {
  switch (action.type) {
    case 'MAJ_REQUETE':
      return { ...state, loadingUpdate: true };
    case 'MAJ_SUCCES':
      return {
        ...state,
        loadingUpdate: false,
        succesUpdate: true,
        user: action.payload,
      };
    case 'MAJ_ECHEC':
      return { ...state, loadingUpdate: false, errorUpdate: action.payload };
    default:
      return state;
  }
};
export default function EcranProfileUtilisateur() {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { infoUtilisateur } = state;
  const [nom, setNom] = useState(infoUtilisateur.nom);
  const [email, setEmail] = useState(infoUtilisateur.email);
  const [mdp, setMdp] = useState('');
  const [confirmeMdp, setConfirmeMdp] = useState('');

  const [
    { loadingUpdate, errorUpdate, succesUpdate, user },
    dispatch,
  ] = useReducer(reducer, {
    loadingUpdate: false,
    errorUpdate: '',
    succesUpdate: false,
    user: {},
  });
  const gestionSoumission = async (e) => {
    e.preventDefault();
    if (mdp !== confirmeMdp) {
      toast.error('Les mots de passe ne coincident pas!');
      return;
    }
    try {
      const { data } = await axios.put(
        '/api/utilisateurs/profile',
        { nom, email, mdp },
        { headers: { Authorization: `Bearer ${infoUtilisateur.token}` } }
      );
      dispatch({ type: 'MAJ_SUCCESS', payload: data });
      ctxDispatch({ type: 'CONNEXION_UTILISATEUR', payload: data });
      localStorage.setItem('infoUtilisateur', JSON.stringify(data));
      toast.success('Informations utilisateur mises à jour avec succes!');
    } catch (err) {
      dispatch({ type: 'MAJ_ECHEC', payload: getError(err) });
      toast.error(errorUpdate);
    }
  };
  useEffect(() => {
    if (succesUpdate) {
      setNom(user.nom);
      setEmail(user.email);
    }
  }, [succesUpdate, user]);

  return (
    <div className="container small-container">
      <Helmet>
        <title>Profile Utilisateur</title>
      </Helmet>
      <h1 className="my-3">Profile Utilisateur</h1>
      {loadingUpdate && <WidgetChargement />}
      {errorUpdate && (
        <WidgetMessage variant="danger">{errorUpdate}</WidgetMessage>
      )}
      <form onSubmit={gestionSoumission}>
        <FormGroup className="mb-3" controlId="name">
          <FormLabel>Nom</FormLabel>
          <FormControl
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="password">
          <FormLabel>Mot de passe</FormLabel>
          <FormControl
            type="password"
            onChange={(e) => setMdp(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="password">
          <FormLabel>Confirmer le mot de passe</FormLabel>
          <FormControl
            type="password"
            onChange={(e) => setConfirmeMdp(e.target.value)}
          ></FormControl>
        </FormGroup>
        <div className="mb-3">
          <Button type="submit">Mettre A Jour</Button>
        </div>
      </form>
    </div>
  );
}
