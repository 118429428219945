import React, { useContext } from 'react';
import { Store } from '../Store';
import axios from 'axios';
import { Helmet } from 'react-helmet-async';
import { Row, Col, ListGroup, Button, Card } from 'react-bootstrap';
import WidgetMessage from '../composants/WidgetMessage';
import { Link, useNavigate } from 'react-router-dom';

export default function EcranPanier() {
  const navigate = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const {
    panier: { elementsPanier },
  } = state;

  const gestionMAJPanier = async (element, quantite) => {
    const { data } = await axios.get(`api/produits/${element._id}`);
    if (data.nombreEnStock < quantite) {
      window.alert('Désolé. Le produit est en rupture de stock');
      return;
    }
    ctxDispatch({
      type: 'PANIER_AJOUT_ELEMENT',
      payload: { ...element, quantite },
    });
  };

  const gestionRetraitElement = (element) => {
    ctxDispatch({ type: 'PANIER_RETRAIT_ELEMENT', payload: element });
  };

  const gestionCheckout = () => {
    navigate('/signin?redirect=/expedition');
  };
  return (
    <div>
      <Helmet>
        <title>Panier des courses</title>
      </Helmet>
      <h1>Panier des courses</h1>
      <Row>
        <Col md={8}>
          {elementsPanier.length === 0 ? (
            <WidgetMessage>
              Le panier est vide. <Link to="/">Faire les courses</Link>
            </WidgetMessage>
          ) : (
            <ListGroup>
              {elementsPanier.map((element) => (
                <ListGroup.Item key={element._id}>
                  <Row className="align-items-center">
                    <Col md={4}>
                      <img
                        src={element.image}
                        alt={element.nom}
                        className="img-fluid rounded img-thumbnail"
                      />{' '}
                      <Link to={`/produit/${element.slug}`}>{element.nom}</Link>
                    </Col>
                    <Col md={3}>
                      <Button
                        variant="light"
                        onClick={() =>
                          gestionMAJPanier(element, element.quantite - 1)
                        }
                        disabled={element.quantite === 1}
                      >
                        <i className="fas fa-minus-circle"></i>
                      </Button>{' '}
                      <span>{element.quantite}</span>{' '}
                      <Button
                        variant="light"
                        onClick={() =>
                          gestionMAJPanier(element, element.quantite + 1)
                        }
                        disabled={element.quantite === element.nombreEnStock}
                      >
                        <i className="fas fa-plus-circle"></i>
                      </Button>
                    </Col>
                    <Col>{element.prix} XAF</Col>
                    <Col md={2}>
                      <Button
                        onClick={() => gestionRetraitElement(element)}
                        variant="light"
                      >
                        <i className="fas fa-trash"></i>
                      </Button>
                    </Col>
                  </Row>
                </ListGroup.Item>
              ))}
            </ListGroup>
          )}
        </Col>
        <Col md={4}>
          <Card>
            <Card.Body>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h3>
                    Sous-total (
                    {elementsPanier.reduce((a, c) => a + c.quantite, 0)}{' '}
                    éléments) : XAF
                    {elementsPanier.reduce(
                      (a, c) => a + c.prix * c.quantite,
                      0
                    )}
                  </h3>
                </ListGroup.Item>
                <ListGroup.Item>
                  <div className="d-grid">
                    <Button
                      type="button"
                      variant="primary"
                      onClick={() => gestionCheckout()}
                      disabled={elementsPanier.length === 0}
                    >
                      Passer à la caisse
                    </Button>
                  </div>
                </ListGroup.Item>
              </ListGroup>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </div>
  );
}
