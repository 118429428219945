import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import ecrandashboard from '../css/ecrandashboard.css';

export default function DashboardMobileSidebar({
  fermer,
  fonctionFermer,
  ouvrir,
  fonctionOuvrir,
  switchscreen,
  setSwitchScreen,
}) {
  const [imageDeconnexion, setImageDeconnexion] = useState('gris');

  const naviguer = useNavigate();
  const reduction = () => {
    if (!fermer) {
      fonctionFermer('fermer-mobile');
      fonctionOuvrir('');
    }
  };

  const handleSwitchScreen = (stateScreen) => {
    localStorage.removeItem('switchscreen');
    setSwitchScreen(stateScreen);
    localStorage.setItem('switchscreen', stateScreen);
    naviguer('/admin/dashboard');
  };

  const retourAccueil = () => {
    naviguer('/');
  };

  const deconnexion = () => {
    naviguer('/');
  };

  return (
    <div className={` `}>
      <div className={`conteneur-gauche-mobile  ${fermer} ${ouvrir} `}>
        <div className={`enveloppe-mobile ${ouvrir}`}>
          <div className={`zone-titre`}>
            <>
              <div className={`titre `}>Tableau de bord</div>
              <div className={`sous-titre `}>Yves-commerce</div>
              <img
                src="/images/dashboard.png"
                alt=""
                className={`icone-titre`}
              ></img>
            </>
            <>
              <img
                src="/images/precedent.png"
                alt=""
                className="reduire-sidebar"
                onClick={reduction}
              ></img>
            </>
          </div>

          <div className={`zone-element`}>
            <div className={`element`} onClick={retourAccueil}>
              <img
                src="/images/accueil.png"
                alt=""
                className="element-picture"
              ></img>
              <span className={`element-text`}>Accueil</span>
            </div>
            <div
              className={`element`}
              onClick={() => handleSwitchScreen('profil')}
            >
              <img
                src="/images/profil.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('profil')}
              ></img>
              <span className={`element-text `}>Profil</span>
            </div>
            <div
              className={`element`}
              onClick={() => handleSwitchScreen('articles')}
            >
              <img
                src="/images/article.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('articles')}
              ></img>
              <span className={`element-text `}>Articles</span>
            </div>
            <div
              className={`element`}
              onClick={() => handleSwitchScreen('utilisateurs')}
            >
              <img
                src="/images/utilisateurs.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('utilisateurs')}
              ></img>
              <span className={`element-text`}>Utilisateurs</span>
            </div>
            <div
              className={`element`}
              onClick={() => handleSwitchScreen('commandes')}
            >
              <img
                src="/images/commandes.png"
                alt=""
                className="element-picture"
                onClick={() => handleSwitchScreen('commandes')}
              ></img>
              <span className={`element-text`}>Commandes</span>
            </div>
          </div>
          <div className={`zone-deconnexion`}>
            <hr className={`ligne-deconnexion`} />
            <div className="element-deconnexion" onClick={deconnexion}>
              <img
                onMouseOver={() => setImageDeconnexion('rouge')}
                onMouseLeave={() => setImageDeconnexion('gris')}
                src={`/images/deconnexion_${imageDeconnexion}.png`}
                alt=""
                className="element-picture"
              ></img>
              <span className={`element-text `}>Déconnexion</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
