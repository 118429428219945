import React, { useState, useEffect, useContext, useReducer } from 'react';
import {
  Button,
  FormGroup,
  FormLabel,
  FormControl,
  Row,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { Store } from '../Store';
import { Helmet } from 'react-helmet-async';
import { getError } from '../utils';
import axios from 'axios';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import { useNavigate } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'MAJ_REQUETE':
      return { ...state, loadingUpdate: true };
    case 'MAJ_SUCCES':
      return { ...state, loadingUpdate: false };
    case 'MAJ_ECHEC':
      return { ...state, loadingUpdate: false, errorUpdate: action.payload };
    default:
      return state;
  }
};
export default function ProfileAdministrateur() {
  const naviguer = useNavigate();
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { infoUtilisateur } = state;
  console.log(infoUtilisateur);
  const [nom, setNom] = useState(infoUtilisateur.nom);
  const [email, setEmail] = useState(infoUtilisateur.email);
  const [mdp, setMdp] = useState('');
  const [confirmeMdp, setConfirmeMdp] = useState('');

  const [{ loadingUpdate, errorUpdate }, dispatch] = useReducer(reducer, {
    loadingUpdate: false,
    errorUpdate: '',
  });
  const gestionSoumission = async (e) => {
    e.preventDefault();
    if (mdp !== confirmeMdp) {
      toast.error('Les mots de passe ne coincident pas!');
      return;
    }
    try {
      const { data } = await axios.put(
        '/api/utilisateurs/profile',
        { nom, email, mdp },
        { headers: { Authorization: `Bearer ${infoUtilisateur.token}` } }
      );
      dispatch({ type: 'MAJ_SUCCESS' });
      ctxDispatch({ type: 'CONNEXION_UTILISATEUR', payload: data });
      localStorage.setItem('infoUtilisateur', JSON.stringify(data));
      toast.success('Informations utilisateur mises à jour avec succes!');
    } catch (err) {
      dispatch({ type: 'MAJ_ECHEC' });
      toast.error(getError(err));
    }
  };

  useEffect(() => {
    // if (!infoUtilisateur.estAdmin) {
    //   naviguer('/');
    // }
  }, [naviguer, infoUtilisateur]);

  return (
    <div className="container small-container">
      <Helmet>
        <title>Profile Administrateur</title>
      </Helmet>
      <h1 className="mt-3 mb-4">Profile Administrateur</h1>
      {loadingUpdate && <WidgetChargement />}
      {errorUpdate && (
        <WidgetMessage variant="danger">{errorUpdate}</WidgetMessage>
      )}
      <form onSubmit={gestionSoumission}>
        <FormGroup className="mb-3" controlId="name">
          <FormLabel>Nom</FormLabel>
          <FormControl
            type="name"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
            required
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="email">
          <FormLabel>Email</FormLabel>
          <FormControl
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="password">
          <FormLabel>Mot de passe</FormLabel>
          <FormControl
            type="password"
            onChange={(e) => setMdp(e.target.value)}
          ></FormControl>
        </FormGroup>
        <FormGroup className="mb-3" controlId="password">
          <FormLabel>Confirmer le mot de passe</FormLabel>
          <FormControl
            type="password"
            onChange={(e) => setConfirmeMdp(e.target.value)}
          ></FormControl>
        </FormGroup>
        <Row className="mt-5 mb-3 px-2">
          <Button type="submit">Mettre le profil à Jour</Button>
        </Row>
      </form>
    </div>
  );
}
