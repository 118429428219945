import axios from 'axios';
import React, { useContext, useEffect, useReducer, useState } from 'react';
import { Button, Container, Form } from 'react-bootstrap';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_DETAILS_USER_REQUETE':
      return { ...state, loadingDetails: true };
    case 'IMPORT_DETAILS_USER_SUCCES':
      return { ...state, loadingDetails: false, user: action.payload };
    case 'IMPORT_DETAILS_USER_ECHEC':
      return { ...state, loadingDetails: false, errorDetails: action.payload };
    case 'MODIFIER_UTILISATEUR_REQUETE':
      return { ...state, loadingModifier: true };
    case 'MODIFIER_UTILISATEUR_SUCCES':
      return {
        ...state,
        loadingModifier: false,
        succesModifier: true,
        messageModifier: action.payload,
      };
    case 'IMODIFIER_UTILISATEUR_ECHEC':
      return {
        ...state,
        loadingModifier: false,
        errorModifier: action.payload,
      };
    default:
      return state;
  }
};

export default function EcranModifierUtilisateur() {
  const userId = useParams();
  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  const [
    {
      loadingDetails,
      loadingModifier,
      errorDetails,
      errorModifier,
      user,
      messageModifier,
    },
    dispatch,
  ] = useReducer(reducer, {
    loadingDetails: false,
    loadingModifier: false,
    errorDetails: '',
    errorModifier: '',
    user: {},
    messageModifier: '',
  });

  const [nom, setNom] = useState('');
  const [email, setEmail] = useState('');
  const [isAdmin, setIsAdmin] = useState(false);
  console.log(isAdmin);

  useEffect(() => {
    const importDetailsUser = async () => {
      try {
        dispatch({ type: 'IMPORT_DETAILS_USER_REQUETE' });
        const { data } = await axios.get(`/api/utilisateurs/${userId.id}`, {
          headers: { Authorization: ` Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'IMPORT_DETAILS_USER_SUCCES', payload: data });
        console.log(data);
      } catch (error) {
        dispatch({
          type: 'IMPORT_DETAILS_USER_ECHEC',
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };
    if (!user.nom || user._id !== userId.id) {
      importDetailsUser();
    } else {
      setNom(user.nom);
      setEmail(user.email);
      setIsAdmin(user.estAdmin);
    }
  }, [user, userId]);

  const gestionValidation = async (e) => {
    e.preventDefault();
    try {
      dispatch({ type: 'MODIFIER_UTILISATEUR_REQUETE' });
      const { data } = await axios.put(
        `/api/utilisateurs/${userId.id}`,
        { nom, email, isAdmin },
        {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        }
      );
      dispatch({ type: 'MODIFIER_UTILISATEUR_SUCCES', payload: data });
      toast.success(messageModifier);
    } catch (error) {
      dispatch({
        type: 'MODIFIER_UTILISATEUR_ECHEC',
        payload:
          error.response && error.response.data.message
            ? error.response.data.message
            : error.message,
      });
      toast.error(errorModifier);
    }
  };

  return (
    <Container className="small-container mt-5">
      <Helmet>
        <title>Mise à jour utilisateur</title>
      </Helmet>
      <h1 className="my-3">Mettre cet utilisateur à jour</h1>
      <Form onSubmit={gestionValidation} className="mt-5">
        <Form.Group className="mb-3" controlId="name">
          <Form.Label>Nom</Form.Label>
          <Form.Control
            type="name"
            value={nom}
            onChange={(e) => setNom(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="email">
          <Form.Label>Email</Form.Label>
          <Form.Control
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          ></Form.Control>
        </Form.Group>
        <Form.Group className="mb-3" controlId="checkbox">
          <Form.Label>Administrateur</Form.Label>
          <Form.Check
            type="checkbox"
            value={isAdmin}
            checked={isAdmin === true}
            onChange={(e) => setIsAdmin(!isAdmin)}
          ></Form.Check>
        </Form.Group>
        <div className="mb-3">
          <Button type="submit">Mettre l'utilisateur à jour</Button>
        </div>
      </Form>
    </Container>
  );
}
