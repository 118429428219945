import React, { useEffect, useReducer } from 'react';
import axios from 'axios';
import { Row, Col, Container } from 'react-bootstrap';
import Produit from '../composants/Produit';
import { Helmet } from 'react-helmet-async';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import Banner from '../composants/Banner';
import Paginateur from '../composants/Paginateur';
import { useParams } from 'react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'FETCH_REQUEST':
      return { ...state, loading: true };
    case 'FETCH_SUCCESS':
      return {
        ...state,
        loading: false,
        produits: action.payload.produits,
        page: action.payload.page,
        pages: action.payload.pages,
      };
    case 'FETCH_FAIL':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};

function EcranAccueil() {
  const { numeroPage } = useParams();
  const [{ loading, error, produits, page, pages }, dispatch] = useReducer(
    reducer,
    {
      produits: [],
      page: '',
      pages: '',
      loading: true,
      error: '',
    }
  );

  useEffect(() => {
    const importerDonnees = async () => {
      try {
        dispatch({ type: 'FETCH_REQUEST' });
        const resultat = await axios.get(
          `/api/produits?numeroPage=${numeroPage}`
        );
        dispatch({ type: 'FETCH_SUCCESS', payload: resultat.data });
        console.log(resultat.data);
      } catch (error) {
        dispatch({ type: 'FETCH_FAIL', payload: error.message });
      }
    };
    importerDonnees();
  }, [dispatch, numeroPage]);
  return (
    <div>
      <Helmet>
        <title>Bienvenu(e) chez YVES-COMMERCE</title>
      </Helmet>

      <Banner />

      <Container>
        <h2 className="featured-message">Retrouvez dans nos magasins</h2>
        <div className="produits">
          {loading ? (
            <WidgetChargement />
          ) : error ? (
            <WidgetMessage variant="danger">{error}</WidgetMessage>
          ) : (
            <Row>
              {produits.map((produit) => (
                <Col sm={6} md={4} lg={3} className="mb-3" key={produit.slug}>
                  <Produit produit={produit} />
                </Col>
              ))}
            </Row>
          )}
        </div>
        <Paginateur pages={pages} page={page} />
      </Container>
    </div>
  );
}

export default EcranAccueil;
