import React, { createContext, useReducer } from 'react';

const initialState = {
  infoUtilisateur: localStorage.getItem('infoUtilisateur')
    ? JSON.parse(localStorage.getItem('infoUtilisateur'))
    : null,

  panier: {
    adresseExpedition: localStorage.getItem('adresseExpedition')
      ? JSON.parse(localStorage.getItem('adresseExpedition'))
      : {},
    methodePaiement: localStorage.getItem('methodePaiement')
      ? localStorage.getItem('methodePaiement')
      : '',
    elementsPanier: localStorage.getItem('elementsPanier')
      ? JSON.parse(localStorage.getItem('elementsPanier'))
      : [],
  },
};

function reducer(state, action) {
  switch (action.type) {
    case 'PANIER_AJOUT_ELEMENT':
      // Ajouter dans le panier
      const nouvelElement = action.payload;
      const elementExiste = state.panier.elementsPanier.find(
        (element) => element._id === nouvelElement._id
      );
      const elementsPanier = elementExiste
        ? state.panier.elementsPanier.map((element) =>
            element._id === elementExiste._id ? nouvelElement : element
          )
        : [...state.panier.elementsPanier, nouvelElement];
      localStorage.setItem('elementsPanier', JSON.stringify(elementsPanier));

      return { ...state, panier: { ...state.panier, elementsPanier } };
    case 'PANIER_RETRAIT_ELEMENT': {
      const elementsPanier = state.panier.elementsPanier.filter(
        (element) => element._id !== action.payload._id
      );
      localStorage.setItem('elementsPanier', JSON.stringify(elementsPanier));
      return { ...state, panier: { ...state.panier, elementsPanier } };
    }
    case 'PANIER_VIDE':
      return { ...state, panier: { ...state.panier, elementsPanier: [] } };
    case 'CONNEXION_UTILISATEUR':
      return { ...state, infoUtilisateur: action.payload };
    case 'DECONNEXION_UTILISATEUR':
      return {
        ...state,
        infoUtilisateur: null,
        panier: {
          elementsPanier: [],
          adresseExpedition: {},
          methodePaiemant: '',
        },
      };
    case 'SAUVEGARDER_ADRESSE_EXPEDITION':
      return {
        ...state,
        panier: {
          ...state.panier,
          adresseExpedition: action.payload,
        },
      };
    case 'SAUVEGARDER_METHODE_PAIEMENT':
      return {
        ...state,
        panier: { ...state.panier, methodePaiement: action.payload },
      };
    default:
      return state;
  }
}

export const Store = createContext();

export function StoreProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <Store.Provider value={value}>{props.children}</Store.Provider>;
}
