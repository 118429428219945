import axios from 'axios';
import React, { useContext, useEffect, useReducer } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import { getError } from '../utils';
import { Store } from '../Store';

const reducer = (state, action) => {
  switch (action.type) {
    case 'IMPORT_REQUETE':
      return { ...state, loading: true };
    case 'IMPORT_SUCCES':
      return { ...state, commandes: action.payload, loading: false };
    case 'IMPORT_ECHEC':
      return { ...state, loading: false, error: action.payload };
    default:
      return state;
  }
};
export default function EcranHistoriqueCommande() {
  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  const navigate = useNavigate();

  const [{ loading, commandes, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: '',
  });

  useEffect(() => {
    const importDonnees = async () => {
      dispatch({ type: 'IMPORT_REQUETE' });
      try {
        const { data } = await axios.get('/api/commandes/miennes', {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });
        dispatch({ type: 'IMPORT_SUCCES', payload: data });
      } catch (error) {
        dispatch({ type: 'IMPORT_ECHEC', payload: getError(error) });
      }
    };
    importDonnees();
  }, [infoUtilisateur, dispatch]);

  return (
    <div>
      <Helmet>
        <title>Historique des commandes</title>
      </Helmet>
      <h1>Historique des commandes</h1>
      {loading ? (
        <WidgetChargement />
      ) : error ? (
        <WidgetMessage variant="danger">{error}</WidgetMessage>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>ID</th>
              <th>DATE</th>
              <th>TOTAL</th>
              <th>PAYEE</th>
              <th>LIVREE</th>
              <th>ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {commandes.map((commande) => (
              <tr key={commande._id}>
                <td>{commande._id}</td>
                <td>{commande.createdAt.substring(0, 10)}</td>
                <td>{commande.prixTotal.toFixed(2)}</td>
                <td>
                  {commande.estPaye ? commande.payeLe.substring(0, 10) : 'Non'}
                </td>
                <td>
                  {commande.estLivre
                    ? commande.livreLe.substring(0, 10)
                    : 'Non'}
                </td>
                <td>
                  <Button
                    type="button"
                    variant="light"
                    onClick={() => navigate(`/commande/${commande._id}`)}
                  >
                    Détails
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}
