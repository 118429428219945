import React, { useEffect, useReducer } from 'react';
import { FlutterWaveButton, closePaymentModal } from 'flutterwave-react-v3';
import axios from 'axios';
import { getError } from '../utils';
import { toast } from 'react-toastify';
import WidgetChargement from './WidgetChargement';
import WidgetMessage from './WidgetMessage';

function reducer(state, action) {
  switch (action.type) {
    case 'PAY_REQUEST':
      return { ...state, loading: true };
    case 'PAY_SUCCES':
      return { ...state, loading: false, success: true };
    case 'PAY_FAIL':
      return { ...state, loading: false, error: action.payload };
    case 'PAY_RESET':
      return { ...state, loading: false, success: false };
    default:
      return state;
  }
}

export default function FlutterWave({ commande, infoUtilisateur }) {
  const [{ loading, error, success }, dispatch] = useReducer(reducer, {
    loading: false,
    error: '',
    success: false,
  });
  const config = {
    public_key: 'FLWPUBK_TEST-c08713624897b8268338be43c17b1de3-X',
    tx_ref: `${commande._id}_${Date.now()}`,
    amount: commande.prixTotal,
    currency: 'XAF',
    payment_options: 'mobilemoneyfranco',
    customer: {
      email: infoUtilisateur.email,
      name: infoUtilisateur.nom,
    },
    customizations: {
      title: 'Yves Commerce',
      description: 'Paiement de votre commande',
      logo: 'images/merci_client.jpg',
    },
  };
  const fwConfig = {
    ...config,
    text: 'Payer maintenant',
    callback: async (response) => {
      console.log(response);
      try {
        dispatch({ type: 'PAY_REQUEST' });
        const { data } = await axios.put(
          `/api/commandes/${commande._id}/pay`,
          response,
          { headers: { Authorization: `Bearer ${infoUtilisateur.token}` } }
        );
        dispatch({ type: 'PAY_SUCCES', payload: data });
        toast.success('Votre commande a été payée');
      } catch (err) {
        dispatch({ type: 'PAY_FAIL', payload: getError(err) });
        toast.error(getError(err));
      }
      closePaymentModal(); // Cette appel à closePaymentModal ferme la fenêtre modale
    },
    onClose: () => {},
  };

  useEffect(() => {
    if (success) {
      dispatch({ type: 'PAY_RESET' });
    }
  }, [success, dispatch]);

  return loading ? (
    <WidgetChargement />
  ) : error ? (
    <WidgetMessage variant="danger">{error}</WidgetMessage>
  ) : (
    <FlutterWaveButton className="flutterwave-button" {...fwConfig} />
  );
}
