import React from 'react';
import { Pagination } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';

export default function Paginateur({ pages, page }) {
  return (
    pages > 1 && (
      <Pagination className="my-5 text-center">
        {[...Array(pages).keys()].map((x) => (
          <LinkContainer key={x + 1} to={`/article/page/${x + 1}`}>
            <Pagination.Item className="" active={x + 1 === page}>
              {x + 1}
            </Pagination.Item>
          </LinkContainer>
        ))}
      </Pagination>
    )
  );
}
