import React, { useContext, useEffect, useReducer } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import {
  Table,
  Button,
  Row,
  Col,
  Pagination,
  Container,
} from 'react-bootstrap';
import WidgetChargement from '../composants/WidgetChargement';
import WidgetMessage from '../composants/WidgetMessage';
import axios, { Axios } from 'axios';
import { Store } from '../Store';
import { toast } from 'react-toastify';
import { getError } from '../utils';
import { Helmet } from 'react-helmet-async';

const reducer = (state, action) => {
  switch (action.type) {
    case 'LISTE_ARTICLES_REQUETE':
      return { ...state, loadingListeArticles: true };
    case 'LISTE_ARTICLES_SUCCES':
      return {
        ...state,
        loadingListeArticles: false,
        articles: action.payload.produits,
        pages: action.payload.pages,
        page: action.payload.page,
      };
    case 'LISTE_ARTICLES_ECHEC':
      return {
        ...state,
        loadingListeArticles: false,
        echecListeArticles: action.payload,
      };
    case 'SUPPRIMER_ARTICLE_REQUETE':
      return { ...state, loadingSupprimerArticle: true };
    case 'SUPPRIMER_ARTICLE_SUCCES':
      return {
        ...state,
        loadingSupprimerArticle: false,
        succesSupprimer: true,
      };
    case 'SUPPRIMER_ARTICLE_ECHEC':
      return {
        ...state,
        loadingSupprimerArticle: false,
        echecSupprimerArticle: action.payload,
      };
    case 'SUPPRIMER_ARTICLE_RESET':
      return {
        ...state,
        loadingSupprimerArticle: false,
        succesSupprimer: false,
      };
    case 'CREATION_ARTICLE_REQUETE':
      return { ...state, loadingCreationArticle: true };
    case 'CREATION_ARTICLE_SUCCES':
      return {
        ...state,
        loadingCreationArticle: false,
        succesCreation: true,
        nouvelArticle: action.payload,
      };
    case 'CREATION_ARTICLE_ECHEC':
      return {
        ...state,
        loadingCreationArticle: false,
        echecCreationArticle: action.payload,
      };
    default:
      return state;
  }
};

export default function EcranListeArticles({ lespages, lapage }) {
  const { numeroPage } = useParams();
  const { state } = useContext(Store);
  const { infoUtilisateur } = state;
  const [
    {
      loadingListeArticles,
      loadingCreationArticle,
      loadingSupprimerArticle,
      articles,
      pages,
      page,
      succesCreation,
      nouvelArticle,
      succesSupprimer,
      echecCreationArticle,
      echecSupprimerArticle,
      echecListeArticles,
    },
    dispatch,
  ] = useReducer(reducer, {
    loadingListeArticles: false,
    loadingCreationArticle: false,
    loadingSupprimerArticle: false,
    articles: [],
    pages: 0,
    page: '',
    succesCreation: false,
    nouvelArticle: {},
    succesSupprimer: false,
    echecListeArticles: '',
    echecCreationArticle: '',
    echecSupprimerArticle: '',
  });

  const naviger = useNavigate();

  useEffect(() => {
    // if (!infoUtilisateur.estAdmin) {
    //   naviger('/signin');
    // }
    const importArticles = async () => {
      try {
        dispatch({ type: 'LISTE_ARTICLES_REQUETE' });

        const { data } = await axios.get(
          `/api/produits?numeroPage=${numeroPage}`,
          {
            headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
          }
        );
        dispatch({ type: 'LISTE_ARTICLES_SUCCES', payload: data });
      } catch (error) {
        dispatch({
          type: 'LISTE_ARTICLES_ECHEC',
          payload:
            error.response && error.response.data.message
              ? error.response.data.message
              : error.message,
        });
      }
    };
    if (succesCreation && nouvelArticle.nom) {
      naviger(`/admin/dashboard/article/edit/${nouvelArticle._id}`);
    } else if (succesSupprimer) {
      toast.success('Le produit a été supprimé avec succès.');
      dispatch({ type: 'SUPPRIMER_ARTICLE_RESET' });
      importArticles();
    } else {
      importArticles();
    }
  }, [
    dispatch,
    infoUtilisateur,
    succesCreation,
    succesSupprimer,
    naviger,
    nouvelArticle,
    numeroPage,
  ]);

  const gestionCreationArticle = async () => {
    try {
      dispatch({ type: 'CREATION_ARTICLE_REQUETE' });

      const { data } = await axios.post(
        `/api/produits/creer`,
        {
          nom: 'nom ',
          slug: 'slug ',
          image: '/images/windows_surface.png',
          brand: 'lamy',
          categorie: 'appareil electronique',
          description: 'un appareil électronique de plus',
          prix: 0,
          nombreEnStock: 0,
          note: 0,
          nombreDAvis: 0,
        },
        {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        }
      );
      dispatch({ type: 'CREATION_ARTICLE_SUCCES', payload: data });
      toast.success("L'article a été crée avec succès.");
    } catch (error) {
      dispatch({
        type: 'CREATION_ARTICLE_ECHEC',
        payload: getError(error),
      });
      toast.error(echecCreationArticle);
    }
  };

  const gestionSuppressionArticle = async (id) => {
    if (window.confirm('Etes-vous sûre de vouloir supprimer cet article ?')) {
      try {
        dispatch({ type: 'SUPPRIMER_ARTICLE_REQUETE' });

        await axios.delete(`/api/produits/${id}`, {
          headers: { Authorization: `Bearer ${infoUtilisateur.token}` },
        });

        dispatch({ type: 'SUPPRIMER_ARTICLE_SUCCES' });
        toast.success("L'article a été supprimer avec succès.");
      } catch (error) {
        dispatch({
          type: 'SUPPRIMER_ARTICLE_ECHEC',
          payload: getError(error),
        });
        toast.error(echecSupprimerArticle);
      }
    }
  };

  // lespages(pages);
  // lapage(page);
  return (
    <Container>
      <Helmet>
        <title>Liste des Articles</title>
      </Helmet>
      <Row className="mt-4 mb-5 ">
        <Col md={8} sm={12}>
          {' '}
          <h2 className="couleur-titre-standard">Liste des articles</h2>
        </Col>
        <Col md={4} sm={12}>
          <Button className="btn mt-1 " onClick={gestionCreationArticle}>
            <i className="fas fa-plus"></i> Créer un article
          </Button>
          {loadingCreationArticle && <WidgetChargement />}
        </Col>
      </Row>

      <Row>
        <Col md={12}>
          {loadingSupprimerArticle && <WidgetChargement />}
          {echecSupprimerArticle && (
            <WidgetMessage variant="danger">
              {echecSupprimerArticle}
            </WidgetMessage>
          )}
          {echecCreationArticle && (
            <WidgetMessage variant="danger">
              {echecCreationArticle}
            </WidgetMessage>
          )}
        </Col>
      </Row>

      {loadingListeArticles ? (
        <WidgetChargement />
      ) : echecListeArticles ? (
        <Row>
          <Col md={12}>
            <WidgetMessage variant="danger">{echecListeArticles}</WidgetMessage>
          </Col>
        </Row>
      ) : (
        <>
          <Table striped bordered hover responsive className="table-sm mb-5">
            <thead>
              <tr>
                <th>ID</th>
                <th>Nom</th>
                <th>Categorie</th>
                <th>Prix</th>
                <th>Note</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {articles.map((article) => (
                <tr key={article._id}>
                  <td>{article._id}</td>
                  <td>{article.nom}</td>
                  <td>{article.categorie}</td>
                  <td>{article.prix}</td>
                  <td>{article.note}</td>
                  <td>
                    <LinkContainer
                      to={`/admin/dashboard/article/edit/${article._id}`}
                    >
                      <Button variant="light" className="btn-sm">
                        <i className="fas fa-edit"></i>
                      </Button>
                    </LinkContainer>
                  </td>
                  <td>
                    <Button
                      variant="danger"
                      className="btn-sm"
                      onClick={() => gestionSuppressionArticle(article._id)}
                    >
                      <i className="fas fa-trash"></i>
                    </Button>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </>
      )}
      {pages > 1 && (
        <Pagination className="my-5 text-center">
          {[...Array(pages).keys()].map((x) => (
            <LinkContainer key={x + 1} to={`/admin/dashboard/article/${x + 1}`}>
              <Pagination.Item className="" active={x + 1 === page}>
                {x + 1}
              </Pagination.Item>
            </LinkContainer>
          ))}
        </Pagination>
      )}
    </Container>
  );
}
